<template>
  <div
    v-if="!!end"
    class="count-down">
    <div
      v-if="statusType==='expired'"
      class="expired has-background-danger has-text-white">
      <slot name="expired">
        {{ $t('components.CountDown.expired.title') }}
      </slot>
    </div>
    <div
      v-else
      class="running has-background-success has-text-white">
      <span class="is-size-5 has-text-weight-bold"><i class="el-icon-alarm-clock m-r" /> <slot name="title">{{ $t('components.CountDown.title') }}</slot>  {{ hours }}:{{ minutes }}:{{ seconds }}</span>
    </div>
    <PpModal v-model="expired">
      <PpCard class="box has-border is-flex">
        <div class="has-text-centered expired-modal-content">
          <div class="m-b-3x">
            <IconBase
              width="44"
              height="70">
              <IconTimeout />
            </IconBase>
          </div>
          <div class="has-text-secondary has-text-weight-bold is-size-4">
            {{ $t('components.CountDown.expired.title') }}
          </div>
          <div class="m-b-6x is-size-6">
            {{ $t('components.CountDown.expired.desc') }}
          </div>
          <div>
            <router-link
              class="el-button button-secondary"
              style="min-width: 203px;"
              :to="backUrl">
              {{ $t('components.CountDown.expired.button') }}
            </router-link>
          </div>
        </div>
      </PpCard>
    </PpModal>
  </div>
</template>

<script>
/* eslint-disable vue/require-prop-types */

import padStart from 'lodash/padStart'
import IconTimeout from '../../components/icons/IconTimeout'
import { mapGetters } from 'vuex'
import { getCheckoutExpiredAt } from '@/components/CountDown/checkoutExpired'

export default {
  name: 'CountDown',
  components: {
    IconTimeout
  },
  props: [
    'startTime',
    'endTime'
  ],
  data: function () {
    return {
      timer: '',
      start: '',
      end: '',
      interval: '',
      days: '',
      minutes: '',
      hours: '',
      seconds: '',
      statusType: '',
      expired: false
    }
  },
  computed: {
    ...mapGetters('bookingPolicy', ['backUrl'])
  },
  watch: {
    'statusType': {
      handler (nV) {
        if (nV === 'expired') {
          this.expired = true
        }
      }
    }
  },
  mounted () {
    if (this.startTime) {
      this.start = new Date(this.startTime).getTime()
    } else {
      this.start = new Date().getTime()
    }

    if (this.endTime) {
      this.end = new Date(this.endTime).getTime()
    } else {
      const endTime = getCheckoutExpiredAt(this.$route.params.ckToken)
      this.end = new Date(endTime).getTime()
    }
    if (!this.end) return
    // Update the count down every 1 second
    this.timerCount(this.start, this.end)
    this.interval = setInterval(() => {
      this.timerCount(this.start, this.end)
    }, 1000)
  },
  methods: {
    timerCount (start, end) {
      // Get todays date and time
      let now = new Date().getTime()
      // Find the distance between now an the count down date
      let distance = start - now
      let passTime = end - now

      if (distance < 0 && passTime < 0) {
        this.statusType = 'expired'
        clearInterval(this.interval)
      } else if (distance < 0 && passTime > 0) {
        this.calcTime(passTime)
        this.statusType = 'running'
      } else if (distance > 0 && passTime > 0) {
        this.calcTime(distance)
        this.statusType = 'upcoming'
      }
    },
    calcTime (dist) {
      // Time calculations for days, hours, minutes and seconds
      this.days = Math.floor(dist / (1000 * 60 * 60 * 24))
      this.hours = padStart(Math.floor((dist % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)), 2, '0')
      this.minutes = padStart(Math.floor((dist % (1000 * 60 * 60)) / (1000 * 60)), 2, '0')
      this.seconds = padStart(Math.floor((dist % (1000 * 60)) / 1000), 2, '0')
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../styles/bulma-variables';

  .count-down {
    .expired,.running {
      padding: 5px 14px;
      font-size: $size-6;
    }
  }
</style>
<style lang="scss">
  @import '../../styles/bulma-variables';
  .expired-modal-content {
    @include tablet {
      min-width: 450px;
    }
  }
</style>
