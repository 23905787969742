<template>
  <div style="min-height: 100vh">
    <PpModal
      v-model="modalVisibility"
      :is-loading="isLoading"
      :loading-message="$t('views.breadcrumb.Processing')">
      <div class="box">
        <div
          v-if="postBookError"
          class="box-content has-text-centered">
          <IconBase
            width="72"
            height="72">
            <IconPaymentFailed />
          </IconBase>
          <h2 class="is-size-5 has-text-danger">
            {{ $t('views.book.PaymentFailedPopUp.title') }}
          </h2>
          <p>{{ $t('views.book.PaymentFailedPopUp.message') }}</p>
          <p>{{ $t('views.book.PaymentFailedPopUp.details',{details: postBookError || '-'}) }}</p>
          <PpGap />
          <div>
            <p>
              No OTP? Payment issue? Contact us on&nbsp;
              <a
                href="tel:+27210125858"
                title="tel:+27210125858"
                style="color:#00A0D8;">+27 21 012 5858</a>
              &nbsp;for more options.
            </p>
          </div>
          <PpGap />
          <el-button
            size="small"
            class="button-secondary m-t-2x"
            style="min-width: 155px"
            @click="handleOk">
            {{ $t('app.ok') }}
          </el-button>
        </div>
      </div>
    </PpModal>
  </div>
</template>
<script>
import store from '@/store'
import { debug } from '@/plugins/util'
import IconPaymentFailed from '@/components/icons/IconPaymentFailed'

const postBook = async (orderReference, isBulk) => {
  try {
    let payload = {}
    let resp
    if (isBulk) {
      payload.eventType = 'paymentAuth'
      payload.paymentType = 'creditcard'
      payload.orderReferenceList = orderReference.split('_')
      resp = await store.dispatch('bulkPayment/bulkPayment', payload)
    } else {
      payload = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}postBook`))
      payload.eventType = 'paymentAuth'
      payload.payment = {
        paymentType: 'creditcard',
        details: {},
        paymentData: ''
      }
      payload.orderReference = orderReference
      resp = await store.dispatch('bookingPolicy/postBook', payload)
    }
    return resp
  } catch (err) {
    if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
    throw err
  }
}

export default {
  name: 'PaymentProcessing',
  components: {
    IconPaymentFailed
  },
  data () {
    return {
      isLoading: false,
      modalVisibility: false,
      postBookError: undefined,
      paymentUrl: undefined
    }
  },
  async mounted () {
    this.isLoading = true
    this.modalVisibility = true
    const isBulk = this.$route.query && this.$route.query.bulk
    await postBook(this.$route.params.orderReference, isBulk).then(({ outlets }) => {
      if (outlets.details.resultCode === 'Authorised') {
        this.$router.push(outlets.nextStep)
        localStorage.removeItem(`${process.env.VUE_APP_PREFIX_NAME}postBook`)
      } else {
        // handle payment failure
        // if (this.$i18n.locale === 'zh-CN') {
        //   if (outlets.details.refusalReason === 'CVC Declined') {
        //     this.postBookError = 'CVC拒绝'
        //   } else if (outlets.details.refusalReason === 'FRAUD') {
        //     this.postBookError = '诈骗'
        //   } else if (outlets.details.refusalReason === 'Not Submitted') {
        //     this.postBookError = '没有提交'
        //   } else if (outlets.details.refusalReason === 'Transaction Not Permitted') {
        //     this.postBookError = '不允许交易'
        //   } else if (outlets.details.refusalReason === 'Restricted Card') {
        //     this.postBookError = '限制卡'
        //   } else if (outlets.details.refusalReason === 'Revocation Of Auth') {
        //     this.postBookError = '撤销验证'
        //   } else if (outlets.details.refusalReason === 'Declined Non Generic') {
        //     this.postBookError = '拒绝非一般'
        //   } else if (outlets.details.refusalReason === '3d-secure: Authentication failed') {
        //     this.postBookError = '3d-secure: 验证失败'
        //   } else {
        //     this.postBookError = outlets.details.refusalReason || outlets.details.message
        //   }
        // } else {
        //   this.postBookError = outlets.details.refusalReason || outlets.details.message
        // }
        this.postBookError = outlets.details.refusalReason || outlets.details.message
        this.isLoading = false
        this.paymentUrl = outlets.paymentUrl
      }
    }).catch((err) => {
      if (err.error && err.error.code && err.error.code === 'transaction_limit') {
        this.$confirm(this.$t('dailyTransactionLimit.DailyLimitReachedModal.desc'), this.$t('dailyTransactionLimit.DailyLimitReachedModal.title'), {
          confirmButtonText: this.$t('dailyTransactionLimit.DailyLimitReachedModal.Ok'),
          cancelButtonClass: 'is-hidden',
          confirmButtonClass: 'button-secondary w-150',
          dangerouslyUseHTMLString: true,
          customClass: 'dail-limit-modal'
        })
      } else {
        this.isLoading = false
        this.postBookError = err.error ? err.error.message : err.message
        this.paymentUrl = err.error && err.error.resolveActions && err.error.resolveActions.raw && err.error.resolveActions.raw.paymentUrl
      }
      if (debug) console.log(err)
    })
  },
  methods: {
    handleOk () {
      this.modalVisibility = false
      if (!this.paymentUrl) {
        this.paymentUrl = `${process.env.VUE_APP_APP_HOST}/dashboard`
      }
      window.location.href = this.paymentUrl
    }
  }
}
</script>
