<template>
  <div v-if="isVisible">
    <div class="list is-gapless is-narrow">
      <div
        class="list-content">
        <span class="has-text-weight-bold has-text-primary is-size-5">
          Commission
          <el-tooltip placement="bottom">
            <div slot="content">
              Add a commission for this booking only. <br>
              Note the payment required will be the Total <br>
              Price (including any commission you add). <br>
              Once the booking is traveled, eBEDS will pay <br>
              the commission back to you.
            </div>
            <i class="el-icon-info has-text-secondary" />
          </el-tooltip>
        </span>
      </div>
      <div class="list-action has-text-right">
        <el-button
          v-if="!finalAdjustment"
          class="button-secondary"
          style="min-width: 170px;"
          @click="edit">
          Add commission
        </el-button>
        <div
          v-else
          class="edit">
          <div class="has-text-weight-bold is-size-5">
            {{ finalAdjustment|currencyObj }}
          </div>
          <a
            class="has-text-secondary"
            @click.prevent="edit">edit</a>
        </div>
      </div>
    </div>
    <ZuDivider />
    <FinalAdjustmentForm :open.sync="adjustmentFormActive" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { retrieveAgent } from '@/service/auth'
import FinalAdjustmentForm from '@/components/FinalAdjustmentForm'
// import store from '@/store'

export default {
  name: 'FinalAdjustment',
  components: { FinalAdjustmentForm },
  data () {
    return {
      allowPriceAdjustment: false,
      adjustmentFormActive: false
    }
  },
  computed: {
    ...mapState('bookingPolicy', {
      finalAdjustmentForm: state => state.finalAdjustmentForm
    }),
    ...mapGetters('bookingPolicy', ['totalAmount', 'finalAdjustment']),
    isVisible () {
      return this.totalAmount && this.allowPriceAdjustment
    }
  },
  mounted () {
    const agent = retrieveAgent()
    this.allowPriceAdjustment = agent && agent.allowFinalAdjustment
  },
  methods: {
    edit () {
      this.adjustmentFormActive = true
      /* const h = this.$createElement
      this.$msgbox({
        title: 'Add commission',
        message: h(FinalAdjustmentForm),
        showCancelButton: true,
        confirmButtonText: 'Apply',
        cancelButtonText: 'Cancel',
        confirmButtonClass: 'button-secondary',
        cancelButtonClass: 'button-secondary-outline',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            done()
          } else {
            store.commit('bookingPolicy/SET_ADJUSTMENT_FORM', {
              type: 'PERCENTAGE',
              amount: ''
            })
            done()
          }
        }
      }).then(action => {
        // nothing
      }).catch(reason => {
        // nothing
      }) */
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit {
    user-select: none;
  }
</style>
