export default {
  data () {
    return {
      isError: false,
      errorMessage: null
    }
  },
  watch: {
    isError (newValue) {
      if (newValue) {
        this.openErrorModal()
      }
    }
  },
  methods: {
    openErrorModal () {
      this.$store.commit('SET_GLOBAL_MODAL', {
        show: true,
        refresh: true
      })
      throw new Error(this.errorMessage)
    },
    handleError (error) {
      this.errorMessage = error
      this.isError = true
    }
  }
}
