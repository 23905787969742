<template>
  <div class="book-page">
    <!-- Loading Bar start -->
    <progress-indicator
      v-if="isContinuePulling" />
    <!-- Loading Bar end -->
    <!-- title start -->
    <div class="section has-background-primary has-text-white">
      <div class="container is-tablet p-t-10x has-text-centered">
        <OrderMessage
          :status="orderStatus" />
        <p v-if="isContinuePulling === false">
          {{ $t('views.book.PaymentConfirmation.desc') }}: {{ order.orderReference }}
        </p>
      </div>
    </div>
    <!-- title end -->
    <div class="section">
      <div class="container">
        <!--breadcrumb start -->
        <div class="columns m-b-2x">
          <div class="column is-12 is-mobile">
            <PpBreadcrumb
              :routes="breadCrumb"
              class="has-text-weight-bold search-one--breadcrumb is-inline" />
            <span
              v-if="isContinuePulling || isContinuePulling === null"
              class="has-text-secondary has-text-weight-bold">
              {{ $t('views.breadcrumb.Processing') }}
            </span>
            <span
              v-if="isContinuePulling === false"
              class="has-text-secondary has-text-weight-bold">
              {{ $t('views.breadcrumb.Confirmation') }}
            </span>
          </div>
        </div>
        <!--breadcrumb end -->
      </div>
      <div class="container is-tablet half-list-wrapper">
        <!-- error message -->
        <div
          v-if="(orderStatus === 'FAILURE' || orderStatus === 'REFUND_FAILURE') && !isContinuePulling"
          class="columns">
          <div class="column">
            <!-- <div class="has-text-danger">{{ $t('views.book.PaymentFailed.message') }}</div> -->
            <!-- // TODO: change to use code mapping instead. easier to get translated -->
            <!-- pckg.failureCode || payment.failureCode -->
            <div class="has-text-danger">
              {{ pckg.failureMessage || payment.failureMessage }}
            </div>
            <div
              v-if="orderStatus === 'REFUND_FAILURE'"
              class="has-text-danger">
              {{ $t('views.book.PaymentRefundFailure.subtitle') }} <br>
              {{ $t('views.book.PaymentRefundFailure.message', {details: payment.failureMessage || '-'}) }}
            </div>
          </div>
        </div>
        <!-- error message end -->
        <!-- send mail button -->
        <div
          v-if="orderStatus === 'CONFIRMED' || orderStatus === 'ON_HOLD'"
          class="columns">
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary"
              @click="sendEmailModalActive=true">
              {{ $t('views.book.Send to Email') }}
            </el-button>
          </div>
        </div>
        <!-- send mail button end -->
        <div class="list is-gapless is-narrow is-topless  is-bottomless m-b-6x">
          <div class="list-content has-line-height-1-25">
            <StarRatings
              :ratings="property.rating"
              class="is-inline-block is-size-7" />
            <h4 class="is-size-5 has-text-primary has-text-weight-bold has-line-height-1-125">
              <span style="letter-spacing: -0.5px; vertical-align: middle;">{{ property.name }}&nbsp;</span>
            </h4>

            <PropertyAddress
              :location="property.location"
              class="is-size-7" />
          </div>
          <div class="list-action">
            <OrderStatus
              :status="orderStatus | orderStatus"
              style="margin-right: 0;" />
          </div>
        </div>
        <div class="list is-gapless is-narrow is-topless is-bottomless">
          <div class="list-content">
            {{ $t('views.book.PaymentConfirmation.Reservations') }}
          </div>
          <div class="list-action">
            <span class="has-text-weight-bold">{{ pckg.roomCount|roomLabel }}, {{ pckg.checkInDate | countDays({ endDate: pckg.checkOutDate, round: 'down'})|nightLabel }}</span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow is-topless is-bottomless">
          <div class="list-content">
            {{ $t('views.CheckoutFormSummary.check-in-date') }}
          </div>
          <div class="list-action">
            <span class="has-text-weight-bold">{{ pckg.checkInDate | date }}</span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow is-topless  is-bottomless">
          <div class="list-content">
            {{ $t('views.CheckoutFormSummary.check-out-date') }}
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold">{{ pckg.checkOutDate | date }}</span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow is-topless  is-bottomless">
          <div class="list-content">
            {{ $t('views.book.PaymentConfirmation.Booking ID') }}
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold">{{ order.orderReference }}</span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow is-topless  is-bottomless m-b-6x">
          <div class="list-content">
            {{ $t('views.book.PaymentConfirmation.Lead guest') }}
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold">{{ `${contactPerson.firstName} ${contactPerson.lastName}` }}</span>
          </div>
        </div>
        <h3 class="is-size-5 has-text-weight-bold has-text-primary m-b-3x">
          {{ $t('views.book.PaymentConfirmation.Booking details') }}
        </h3>
        <div class="list is-gapless is-narrow is-topless  is-bottomless">
          <div class="list-content">
            {{ $t('views.book.PaymentConfirmation.Occupancy') }}
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold">{{ pckg.adultCount|adultLabel }}, {{ pckg.childrenCount || 0 }} {{ $t('views.CheckoutFormSummary.children') }} {{ childrenAges }} </span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow is-topless  is-bottomless">
          <div class="list-content">
            {{ $t('views.CheckoutFormSummary.room') }}
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold"><span v-if="pckg.supplierDescription">{{ pckg.supplierDescription | truncate }} x</span>{{ pckg.roomCount }}</span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow is-topless  is-bottomless">
          <div class="list-content">
            {{ $t('views.book.PaymentConfirmation.Meal plan') }}
          </div>
          <div
            v-if="$isFoodCodeValid(pckg.foodCode)"
            class="list-action has-text-right">
            <span class="has-text-weight-bold">{{ pckg.foodCode | foodCode }}</span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow is-topless  is-bottomless m-b-6x">
          <div class="list-content">
            {{ $t('views.book.PaymentConfirmation.Special request') }}
            <div class="list-label">
              {{ $t('views.book.PaymentConfirmation.special-request-desc') }}
            </div>
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold">{{ roomLeadGuests.remarks }}</span>
          </div>
        </div>

        <h3 class="is-size-5 has-text-weight-bold has-text-primary m-b-3x">
          {{ $t('views.book.PaymentConfirmation.Cancellation policy') }}
        </h3>
        <div class="list is-gapless is-narrow is-topless  is-bottomless m-b-6x">
          <div class="list-content list-fullwidth">
            <CancellationParse
              hide-tooltip
              :free-cancellation-before="freeCancellationBefore"
              name="freeCancellation" />
          </div>
        </div>
        <h3
          v-if="orderStatus === 'ON_HOLD'"
          class="is-size-5 has-text-weight-bold has-text-primary m-b-3x">
          Your booking is on hold. Please pay before {{ booking.payment.metadata.payLaterDueDate|dateTz({ tz: 'Africa/Johannesburg' }) }} SAST to confirm this booking.
        </h3>
        <h3
          v-if="orderStatus === 'CONFIRMED' && payment.paymentType !== 'pay_at_hotel'"
          class="is-size-5 has-text-weight-bold has-text-primary m-b-3x">
          {{ $t('views.book.PaymentConfirmation.paid') }}
        </h3>
        <h3
          v-if="orderStatus === 'CONFIRMED' && payment.paymentType === 'pay_at_hotel'"
          class="is-size-5 has-text-weight-bold has-text-primary m-b-3x">
          {{ $t('views.book.PaymentConfirmation.pay_at_hotel') }}
        </h3>
        <div class="list is-gapless is-narrow is-topless  is-bottomless m-b">
          <div class="list-content">
            <span v-if="pckg.supplierDescription">{{ pckg.supplierDescription | truncate }}</span>
          </div>
          <div class="list-middle-auto">
            {{ $t('views.CheckoutFormSummary.x') }} {{ pckg.roomCount }}
          </div>
          <div
            class="list-action has-text-right"
            style="padding-right: 0;">
            <span class="has-text-weight-bold is-size-6">
              {{ charge.agentDisplayRate | currencyObj({ factionDigits: 2,excludeTax: false}) }}
            </span>
          </div>
        </div>
        <div
          v-if="finalAdjustments.length && charge.showAdjustments"
          class="list is-gapless is-narrow is-topless  is-bottomless m-b">
          <div
            class="list-content"
            style="align-self: flex-start;">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('views.book.Transaction fee') }}</span>
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ {value: finalAdjustmentAmount.value + additionalFeeAmount.value, currency: additionalFeeAmount.currency} | currencyObj }}</span>
          </div>
        </div>
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow">
          <div
            class="list-content"
            style="align-self: flex-start;">
            <span class="has-text-weight-bold has-text-primary is-size-5">Total amount</span>
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ agentTotalChargeAmount | currencyObj }}</span>
          </div>
        </div>
        <!-- <div class="list is-gapless is-narrow">
          <div
            class="list-content"
            style="align-self: flex-start;">
            <span class="has-text-weight-bold has-text-primary is-size-5">Markup</span>
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ charge.agentMarkupAmount | currencyObj }}</span>
          </div>
        </div> -->
        <div class="list is-gapless is-narrow">
          <div
            class="list-content"
            style="align-self: flex-start;">
            <span class="has-text-weight-bold has-text-primary is-size-5">
              {{ payment.paymentType === 'pay_at_hotel' ? 'Pay at hotel commission' : 'Commission' }}
            </span>
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ agentFinalAdjustmentAmount | currencyObj }}</span>
          </div>
        </div>
        <!-- <div class="list is-gapless is-narrow">
          <div
            class="list-content"
            style="align-self: flex-start;">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('views.book.Transaction fee') }}</span>
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ finalAdjustmentAmount | currencyObj }}</span>
          </div>
        </div> -->
        <ZuDivider class="is-border-light" />
        <div class="list is-gapless is-narrow">
          <div
            class="list-content"
            style="align-self: flex-start;">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('views.CheckoutFormSummary.total-amount') }}</span>
            <div class="has-text-grey-light is-size-7">
              {{ $t('views.CheckoutFormSummary.include') }}
            </div>
          </div>
          <div class="list-action has-text-right">
            <span class="has-text-weight-bold has-text-primary is-size-5">{{ agentActualChargeAmount.value | currency({currency: agentActualChargeAmount.currency,factionDigits: 2}) }}</span>
          </div>
        </div>
        <div
          v-if="payment.paymentType === 'pay_at_hotel'"
          style="margin-top:10px">
          <div class="has-text-weight-semibold is-size-6-5">
            $0.00 due today. Credit Card will be charged upon the guest check-in.
          </div>
        </div>
        <!-- <div
          v-if="charge.totalChargeAmount.currency !== charge.actualChargeAmount.currency"
          class="list-action has-text-grey-light is-size-7">
          {{ $t('views.CheckoutFormSummary.equivalent', {amount: totalEquivalent }) }}
        </div> -->
      </div>
    </div>

    <SendConfirmationEmail
      :open.sync="sendEmailModalActive"
      :email="contactPerson?contactPerson.email:''"
      :order-reference="order?order.orderReference:''"
      :status="order?orderStatus:''"
      :super-admin="false" />

    <PriceChangedPopup
      v-if="v3Status && order && order.orderReference"
      :v3-status="v3Status"
      :booking="bookingData" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'

import CancellationParse from '@/components/CancellationParse'
import StarRatings from '@/components/StarRatings'
import PropertyAddress from '@/components/PropertyAddress'
import OrderStatus from '@/components/OrderStatus'
import OrderMessage from '@/components/OrderMessage'
import SendConfirmationEmail from '@/views/share/SendConfirmationEmail'
import PriceChangedPopup from '@/components/PriceChangedPopup'
import i18n from '@/i18n'
import mixError from '@/mixins/mixError'
import ProgressIndicator from '@/components/ProgressIndicator'

const asyncData = async ({ params }) => {
  try {
    await store.dispatch('order/retrieveOrder', params.orderToken)
  } catch (err) {
    if (err.meta) store.commit('meta/SET_META_ACTION', err.meta)
    if (err.error && err.error.status === 429) {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        okBtn: true,
        title: i18n.t('views.CheckoutComplete.error.429.title'),
        content: i18n.t('views.CheckoutComplete.error.429.content')
      })
    } else if (err.error && err.error.code === 'invalid_ord_token') {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        okBtn: true,
        content: i18n.t('views.CheckoutComplete.error.invalid_ord_token.content')
      })
    } else if (err.error && err.error.code === 'ORDER_NOT_FOUND') {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        okBtn: true,
        content: i18n.t('views.CheckoutComplete.error.booking-not-found.content')
      })
    } else if (err.error && err.error.code === 'page_not_found') {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        okBtn: true,
        content: i18n.t('views.CheckoutComplete.error.page-not-found.content')
      })
    } else if (err.error && err.error.code === 'missing_of_header') {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        okBtn: true,
        content: i18n.t('views.CheckoutComplete.error.page-not-found.content')
      })
    } else {
      store.commit('SET_GLOBAL_MODAL', {
        show: true,
        okBtn: true,
        content: err.message || (err.error && err.error.message) || ''
      })
    }
  }
}

export default {
  name: 'CheckoutComplete',
  components: {
    CancellationParse,
    SendConfirmationEmail,
    PropertyAddress,
    StarRatings,
    OrderStatus,
    OrderMessage,
    PriceChangedPopup,
    ProgressIndicator
  },
  mixins: [mixError],
  async beforeRouteEnter (to, from, next) {
    try {
      if (!to.params.orderToken) {
        store.commit('SET_GLOBAL_MODAL', {
          show: true,
          content: 'Booking not Found'
        })
      }
      await asyncData({ params: to.params })
      next()
    } catch (err) {
      next()
    }
  },
  beforeRouteLeave (to, from, next) {
    clearTimeout(this.pullingTimerHandle)
    next()
  },
  data () {
    return {
      searchQuery: {},
      pullingTimerHandle: null,
      sendEmailModalActive: false,
      counter: 1
    }
  },
  computed: {
    ...mapState('order', {
      booking: state => state.orderDetails,
      pckg: state => state.orderDetails.order.package || {},
      order: state => state.orderDetails.order,
      contactPerson: state => state.orderDetails.order.contactPerson || {},
      property: state => state.orderDetails.property || {},
      charge: state => state.orderDetails.order.charge || {},
      isFetching: state => state.isFetching,
      isContinuePulling: state => state.orderDetails.isContinuePulling,
      payment: state => state.orderDetails.payment || {},
      v3Status: state => state.orderDetails.v3Status,
      bookingData: state => ({ ...state.orderDetails.order.package, propertyName: state.orderDetails.property.name, currency: state.orderDetails.order.charge.agentTotalChargeAmount.currency }),
      backUrl: state => state.orderDetails.backUrl || {}
    }),
    breadCrumb () {
      return [
        {
          label: this.$t('views.breadcrumb.Find Hotel'),
          replace: true,
          route: { name: 'home' }
        }, {
          label: this.$t('views.breadcrumb.Location'),
          replace: true,
          route: {
            path: this.backUrl.location
          }
        }, {
          label: this.property.name,
          replace: true,
          route: { path: this.backUrl.property }
        }, {
          label: this.$t('views.breadcrumb.Book'),
          replace: true,
          route: { name: 'book' },
          static: true
        }, {
          label: this.$t('views.breadcrumb.Payment'),
          replace: true,
          route: { name: 'payment', params: { ordToken: this.$route.query.ordToken } },
          static: true
        }
      ]
    },
    payment () {
      return this.booking.payment
    },
    orderStatus () {
      return this.order.status && this.order.status.code
    },
    roomLeadGuests () {
      try {
        return this.pckg.roomLeadGuests[0]
      } catch (err) {
        this.handleError(err)
        return {}
      }
    },
    childrenAges () {
      if (!this.pckg.childrenAges) return ''
      return `(${this.$t('components.SearchGuest.age')} ${this.pckg.childrenAges.join(',')})`
    },
    totalEquivalent () {
      return this.$options.filters.currency(this.actualChargeAmount.value, { currency: this.actualChargeAmount.currency })
    },
    actualChargeAmount () {
      try {
        return this.charge.actualChargeAmount || {}
      } catch (err) {
        this.handleError(err)
        return {}
      }
    },
    finalAdjustments () {
      try {
        return this.charge.finalAdjustments || []
      } catch (err) {
        this.handleError(err)
        return []
      }
    },
    finalAdjustmentAmount () {
      try {
        return this.charge.finalAdjustmentAmount || { value: 0 }
      } catch (err) {
        this.handleError(err)
        return { value: 0 }
      }
    },
    additionalFeeAmount () {
      try {
        return this.charge.additionalFeeAmount || { value: 0 }
      } catch (err) {
        this.handleError(err)
        return { value: 0 }
      }
    },
    agentActualChargeAmount () {
      try {
        return this.charge.agentActualChargeAmount || {}
      } catch (err) {
        this.handleError(err)
        return {}
      }
    },
    totalChargeAmount () {
      try {
        return this.charge.totalChargeAmount || {}
      } catch (err) {
        this.handleError(err)
        return {}
      }
    },
    orderReference () {
      try {
        return this.order.orderReference || ' '
      } catch (err) {
        this.handleError(err)
        return ' '
      }
    },
    agentTotalChargeAmount () {
      try {
        return this.charge.agentTotalChargeAmount || {}
      } catch (err) {
        this.handleError(err)
        return {}
      }
    },
    agentFinalAdjustmentAmount () {
      try {
        return this.charge.agentFinalAdjustmentAmount || {}
      } catch (err) {
        this.handleError(err)
        return {}
      }
    },
    freeCancellationBefore () {
      try {
        return this.pckg.freeCancellationBefore || ''
      } catch (err) {
        this.handleError(err)
        return ''
      }
    }
  },
  watch: {
    'isContinuePulling': {
      immediate: true,
      handler (nV, oV) {
        if (nV) {
          this.counter += 1
          clearTimeout(this.pullingTimerHandle)
          this.pullingTimerHandle = setTimeout(() => this.polling(), 5000)
          if (this.counter === 12) {
            store.commit('SET_GLOBAL_MODAL', {
              show: true,
              okBtn: true,
              showIcon: false,
              title: 'This might take a while',
              content: 'Sorry, the banks can sometimes take their time to approve a payment, please wait a few minutes or close this window and check later in \'Manage Bookings\'.'
            })
          }
        }
        if (nV === false || this.order.status.code === 'FAILURE') {
          // this.$router.push({ name: 'payment-confirmation', query: this.$route.query })
        }
      }
    }
  },
  created () {
    this.polling()
  },
  mounted () {
    this.$store.commit('order/SET_ORDER_DETAILS', { isContinuePulling: true })
  },
  methods: {
    async polling () {
      this.$store.commit('order/SET_ORDER_DETAILS', { isContinuePulling: null })
      await asyncData({ params: this.$route.params })
    }
  },
  metaInfo () {
    let title = ''
    switch (this.orderStatus) {
    case 'FAILURE':
      title = 'Booking has failed'
      break
    case 'CONFIRMED':
      title = 'Booking is confirmed'
      break
    case 'CANCELLED':
      title = 'Booking is now cancelled'
      break
    case 'REFUND_IN_PROGRESS':
      title = 'Processing your refund'
      break
    case 'REFUND_FAILURE':
      title = 'Unable to process your refund'
      break
    default:
      title = 'Processing Your Booking'
      break
    }
    return {
      title: title
    }
  }
}
</script>

<style scoped>

</style>
