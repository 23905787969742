<template>
  <PpModal v-model="open">
    <PpCard class="box has-border is-flex">
      <div class="edit-profile-modal-content modal-columns-outer">
        <el-form
          v-if="!isEmailSent"
          ref="sendEmailForm"
          :model="sendEmailForm"
          :rules="rules"
          label-position="top"
          status-icon
          class="columns is-multiline"
          @submit.native.prevent>
          <div class="column is-12">
            <h3
              v-if="status === 'CONFIRMED'"
              class="is-size-4 has-text-secondary has-text-weight-bold">
              {{ $t('views.book.email-modal-title') }}
            </h3>
            <h3
              v-if="status === 'CANCELLED'"
              class="is-size-4 has-text-secondary has-text-weight-bold">
              Send Cancellation Email
            </h3>
          </div>
          <el-form-item
            :label="$t('views.customer.form.email.label')"
            class="column is-12"
            prop="email">
            <el-input
              v-model.trim="sendEmailForm.email"
              :placeholder="$t('views.customer.form.email.placeholder')"
              type="email"
              autocomplete="email" />
          </el-form-item>
          <div class="column is-12 has-text-right">
            <el-button
              :disabled="isFetching"
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="handleCancel">
              {{ $t('views.customer.form.cancel') }}
            </el-button>
            <el-button
              :loading="isFetching"
              class="button-secondary"
              style="min-width: 125px;"
              native-type="button"
              @click="handleSendEmail">
              {{ $t('views.book.Send') }}
            </el-button>
          </div>
        </el-form>
        <div
          v-else
          class="columns is-multiline">
          <div class="column is-12">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              {{ $t('views.book.email-sent-title') }}
            </h3>
          </div>
          <div class="column is-12">
            <p v-if="status === 'CONFIRMED'">
              {{ $t('views.book.email-sent-desc',{email:''}) }} <b>{{ sendEmailForm.email }}</b>
            </p>
            <p v-if="status === 'CANCELLED'">
              {{ $t('views.book.cancellation-email-sent-desc',{email:''}) }} <b>{{ sendEmailForm.email }}</b>
            </p>
          </div>
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary"
              style="min-width: 125px;"
              @click="handleClose">
              {{ $t('app.ok') }}
            </el-button>
          </div>
        </div>
      </div>
    </PpCard>
  </PpModal>
</template>

<script>
export default {
  name: 'SendConfirmationEmail',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    orderReference: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    status: {
      type: String,
      default: ''
    },
    superAdmin: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sendEmailForm: {
        email: this.email
      },
      isEmailSent: false,
      rules: {
        email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change', 'blur', 'input'
            ]
          }, {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change', 'blur'
            ]
          }
        ]
      },
      isFetching: false
    }
  },
  watch: {
    email (nV) {
      this.sendEmailForm.email = this.email
    }
  },

  methods: {
    handleSendEmail (e) {
      e.preventDefault()
      this.$refs.sendEmailForm.validate(async (valid) => {
        if (valid) {
          this.isFetching = true
          try {
            await this.$store.dispatch('order/sendEmail', {
              email: this.sendEmailForm.email,
              orderReference: this.orderReference,
              superAdmin: this.superAdmin
            })
            this.isFetching = false
            this.isEmailSent = true
          } catch (err) {
            this.$store.commit('SET_GLOBAL_MODAL', {
              show: true,
              content: err.message || (err.error && err.error.message) || ''
            })
            this.isFetching = false
            this.isEmailSent = false
          }
          return true
        } else {
          return false
        }
      })
    },
    handleClose () {
      this.isEmailSent = false
      this.$emit('update:open', false)
    },
    handleCancel () {
      this.isEmailSent = false
      this.$refs.sendEmailForm.resetFields()
      this.handleClose()
    }
  }
}
</script>

<style scoped>

</style>
