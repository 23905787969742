<template>
  <el-form
    ref="localForm"
    :rules="rules"
    :model="localForm"
    class="columns">
    <div class="column is-12">
      <p class="m-b-2x">
        Would you like to add transfers, tours or tickets? Request it here and our team will contact you within the same day/12 hours.
      </p>
      <el-form-item
        prop="requestTransfer">
        <el-checkbox-group v-model="localForm.requestTransfer">
          <el-checkbox
            v-for="(option, index) in requestTransferOptions"
            :key="option.key"
            :label="option.key"
            :style="index === requestTransferOptions.length - 1 ? 'margin-top: 15px' : ''"
            style="width: 100%"
            @change="v => handleCheckedRequestTransfer(option.key, v)">
            {{ option.label }}
          </el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>

export default {
  name: 'RequestTransferForm',
  props: {
    value: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: Object | undefined,
      default: {
        requestTransfer: []
      }
    }
  },
  data () {
    return {
      localForm: {
        requestTransfer: this.value.requestTransfer
      },
      requestTransferOptions: [
        {
          key: 'ADD_AIRPORT_TRANSFER',
          label: 'Yes! Add (airport) transfer(s) to this booking'
        },
        {
          key: 'ADD_TOUR_TICKET',
          label: 'Yes! Add a tour/activity or tickets to this booking'
        },
        {
          key: 'ONLY_ACCOMMODATION',
          label: 'No, I only need the accommodation'
        }
      ],
      rules: {
        requestTransfer: [
          {
            validator: (rule, value, callback) => {
              if (value.length === 0) {
                callback(new Error('Please check a request of transfer option.'))
              } else {
                callback()
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (newValue) {
        this.localForm = newValue
      }
    }
  },
  methods: {
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.localForm.validate((valid, fields) => {
          resolve({ valid, fields })
        })
      })
    },
    handleCheckedRequestTransfer (key, checked) {
      if (key === 'ONLY_ACCOMMODATION' && checked) {
        if (this.localForm.requestTransfer.length > 0) {
          this.localForm.requestTransfer = []
          this.localForm.requestTransfer.push('ONLY_ACCOMMODATION')
        }
      } else if ((key === 'ADD_AIRPORT_TRANSFER' && checked) || (key === 'ADD_TOUR_TICKET' && checked)) {
        const onlyAccommodationExists = this.localForm.requestTransfer.find(e => e === 'ONLY_ACCOMMODATION')
        if (onlyAccommodationExists) {
          this.localForm.requestTransfer = []
          this.localForm.requestTransfer.push(key)
        }
      }
    }
  }
}
</script>

<style lang="scss">
  .el-form-item__content {
    line-height: 1em;
  }
</style>
