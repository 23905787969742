<template>
  <div class="book-page">
    <div class="section">
      <div class="container">
        <!--breadcrumb start -->
        <div class="columns m-b-2x">
          <div class="column is-12 is-mobile">
            <PpBreadcrumb
              :routes="breadCrumb"
              class="has-text-weight-bold search-one--breadcrumb is-inline" />
            <span class="has-text-secondary has-text-weight-bold">{{ $t('views.book.Payment') }}</span>
          </div>
        </div>
        <!--breadcrumb end -->
        <h2 class="title is-size-3 has-text-primary m-b-5x">
          {{ $t('views.book.Payment') }}
        </h2>
        <div class="columns">
          <div class="column is-8">
            <CountDown class="m-b-2x" />
            <div class="tabs is-boxed">
              <ul>
                <li
                  v-if="$enableWallet() && !$hasRole('CLIENT_AGENT_SB') && wallet.ZAR.isActive && paymentMethod !== 'pay_at_hotel'"
                  :class="{'is-active':paymentMethod==='walletZAR'}"
                  @click="paymentMethodChange('walletZAR')">
                  <a>
                    <span>{{ $t('views.book.Wallet') }} (ZAR)</span>
                  </a>
                </li>
                <li
                  v-if="$enableWallet() && !$hasRole('CLIENT_AGENT_SB') && wallet.USD.isActive && paymentMethod !== 'pay_at_hotel'"
                  :class="{'is-active':paymentMethod==='walletUSD'}"
                  @click="paymentMethodChange('walletUSD')">
                  <a>
                    <span>{{ $t('views.book.Wallet') }} (USD)</span>
                  </a>
                </li>
                <li
                  v-if="paymentMethod !== 'pay_at_hotel'"
                  :class="{'is-active':paymentMethod==='creditcard'}"
                  @click="paymentMethodChange('creditcard')">
                  <a>
                    <span>{{ $t('views.book.Credit card') }}</span>
                  </a>
                </li>
                <li
                  v-if="paymentMethod === 'pay_at_hotel'"
                  :class="{'is-active':paymentMethod === 'pay_at_hotel'}"
                  @click="paymentMethodChange('pay_at_hotel')">
                  <a>
                    <span>{{ $t('views.book.payAtHotel') }}</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="payment-methods">
              <Wallet
                v-if="paymentMethod==='walletZAR' && !postBookError && !$hasRole('CLIENT_AGENT_SB') && $enableWallet()"
                :amount="walletAmount"
                currency="ZAR"
                :enable="$enableWallet()" />
              <Wallet
                v-if="paymentMethod==='walletUSD' && !postBookError && !$hasRole('CLIENT_AGENT_SB') && $enableWallet()"
                :amount="walletAmount"
                currency="USD"
                :enable="$enableWallet()" />
              <div
                v-if="paymentMethod==='creditcard'"
                class="payment-balance">
                <div
                  class="payment-balance-detail"
                  style="margin-bottom:25px;">
                  <!-- <PaygeniusPayment :amount="amount" /> -->
                  <PpSpiner v-if="dropinLoading" />
                  <AdyenPayment
                    :amount="amount"
                    @ready="dropinLoading = false" />
                </div>
                <div class="info-message">
                  <p>
                    No OTP? Payment issue? Contact us on&nbsp;
                    <a
                      href="tel:+27210125858"
                      title="tel:+27210125858"
                      style="color:#00A0D8;">+27 21 012 5858</a>
                    &nbsp;for more options.
                  </p>
                </div>
              </div>

              <div v-if="paymentMethod==='pay_at_hotel'">
                <div style="margin-top:10px">
                  <div class="has-text-weight-semibold is-size-6-5">
                    $0.00 due today. Credit
                    Card will be charged upon the guest check-in.
                  </div>
                </div>
                <PayAtHotelPayment
                  v-if="!isFetchingPaymentSession && pciBookingSession"
                  ref="payAtHotelIframe"
                  :payment-info="pciBookingSession"
                  @paymentFailed="isSubmiting = false" />
              </div>

              <div class="has-text-centered back-link-wrapper">
                <router-link
                  :to="backUrl"
                  class="has-text-secondary has-text-weight-bold">
                  <IconBase
                    width="13"
                    height="13"
                    icon-name="arrow left">
                    <IconArrowLeft />
                  </IconBase>
                  {{ $t('components.CountDown.expired.button') }}
                </router-link>
              </div>
            </div>
          </div>

          <div class="column is-4">
            <CheckoutFormSummary
              v-if="property.name"
              :search-query="searchQuery"
              :property="property"
              :pckg="pckg"
              :payment-type="paymentMethod"
              class="summary-component-content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CheckoutFormSummary from '@/views/book/CheckoutFormSummary'
import AdyenPayment from '@/components/AdyenPayment'
import store from '@/store'
import Wallet from '@/views/payment/Wallet'
import PayAtHotelPayment from '@/views/payment/PayAtHotelPayment'
import CountDown from '@/components/CountDown/index'
import IconArrowLeft from '../../components/icons/IconArrowLeft'
import { retrieveAgent } from '@/service/auth'
const reserveForm = (payload) => {
  return store.dispatch('bookingPolicy/reserveForm', payload)
}
const getPciBookingSession = async () => {
  return store.dispatch('bookingPolicy/getPciBookingSession')
}

export default {
  name: 'Payment',
  components: {
    Wallet,
    AdyenPayment,
    CheckoutFormSummary,
    CountDown,
    IconArrowLeft,
    PayAtHotelPayment
  },
  beforeRouteEnter (to, from, next) {
    const payload = {
      query: { ...to.query, currentState: 'payment' },
      ckToken: to.params.ckToken
    }
    reserveForm(payload).then((resp) => {
      next(vm => {
        const pckg = store.state.bookingPolicy.reserveForm.availability.package
        vm.amount.value = pckg.agentTotalChargeAmount.value
        vm.amount.currency = pckg.agentTotalChargeAmount.currency
        vm.walletAmount.value = pckg.agentTotalChargeAmount.value
        vm.walletAmount.currency = pckg.agentTotalChargeAmount.currency
      })
    }).catch((err) => {
      next(vm => {
        if (err.error.code === 'ck_token_expired') {
          vm.postBookError = {
            title: vm.$t('views.CheckoutForm.package-expired'),
            ...err.error
          }
          // redirect to hotel detail page
          vm.$router.go(-5)
        } else if (err.error && err.error.code === 'ORDER_NOT_FOUND') {
          vm.postBookError = {
            message: err.error.message
          }
        } else {
          vm.postBookError = {
            message: 'There was an issue with your booking. Please contact our customer service for help.'
          }
        }
      })
    })
  },
  data () {
    return {
      postBookError: undefined,
      paymentMethod: this.$route.query.payType,
      amount: {
        value: 0,
        currency: ''
      },
      walletAmount: {
        value: 0,
        currency: ''
      },
      dropinLoading: true,
      wallet: retrieveAgent('wallet'),
      pciBookingTimer: undefined
    }
  },
  computed: {
    ...mapState('bookingPolicy', {
      availability: state => state.reserveForm.availability,
      property: state => state.reserveForm.property,
      searchQuery: state => state.reserveForm.searchQuery,
      backUrl: state => state.reserveForm.backUrl,
      isFetching: state => state.isLoading,
      isSubmitting: state => state.isSubmitting,
      ckToken: state => state.reserveForm.ckToken,
      pciBookingSession: state => state.pciBookingSession,
      isFetchingPaymentSession: state => state.isFetchingPaymentSession,
      finalAdjustment: state => state.finalAdjustmentForm
    }),
    breadCrumb () {
      // FIXME
      return [
        {
          label: this.$t('views.breadcrumb.Find Hotel'),
          replace: true,
          route: { name: 'dashboard' }
        },
        {
          label: this.$t('views.breadcrumb.Location'),
          replace: true,
          route: {
            name: 'search',
            params: { ...this.$store.getters['bookingPolicy/searchQueryParams'].params },
            query: {
              ...this.$store.getters['bookingPolicy/searchQueryParams'].query,
              searchId: this.$newSearchId()
            }
          }
        },
        {
          label: this.property.name,
          replace: true,
          route: { path: this.backUrl }
        },
        {
          label: this.$t('views.breadcrumb.Book'),
          replace: true,
          route: { name: 'book', query: this.$route.query }
        }
      ]
    },
    pckg () {
      return this.availability.package || {}
    }
  },
  created () {
    this.$nextTick(async () => {
      if (this.postBookError !== undefined) {
        // CountDown components will popup error
        // this.$store.commit(
        //   'SET_GLOBAL_MODAL',
        //   { show: true, content: this.postBookError.message },
        //   { root: true }
        // )
      } else {
        // get PCI Booking Session
        if (this.$route.query && this.$route.query.payType === 'pay_at_hotel') {
          await getPciBookingSession()
          console.log(this.pciBookingSession)
          this.pciBookingTimer = setTimeout(async () => {
            await getPciBookingSession()
          }, 120000) // get another session for pci booking every 2 minutes
        }
      }
    })
  },
  methods: {
    paymentMethodChange (method) {
      let query = this.$route.query
      query.payType = method
      if (method === 'walletZAR' || method === 'walletUSD') {
        query = { ...query, currentState: 'reserveForm' }
        if (method === 'walletZAR') {
          query = { ...query, currency: 'ZAR' }
        } else {
          query = { ...query, currency: 'USD' }
        }
      } else {
        query = { ...query, currentState: 'payment' }
      }
      const payload = {
        query,
        ckToken: this.$route.params.ckToken
      }

      this.$router.replace(
        {
          name: 'payment',
          params: { ckToken: payload.ckToken },
          query: payload.query
        }, () => {
          reserveForm(payload).then((resp) => {
            const pckg = store.state.bookingPolicy.reserveForm.availability.package
            this.amount.value = pckg.agentTotalChargeAmount.value
            this.amount.currency = pckg.agentTotalChargeAmount.currency
            this.walletAmount.value = pckg.agentTotalChargeAmount.value
            this.walletAmount.currency = pckg.agentTotalChargeAmount.currency
          })
        }
      )
    }
  },
  metaInfo () {
    return {
      title: 'Payment'
    }
  }
}
</script>

<style scoped>
  .info-message {
    padding: 0px 25px;
  }

  .back-link-wrapper {
    margin-top: 55px;
  }
</style>
