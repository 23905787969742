<template>
  <el-form
    ref="localForm"
    class="columns is-multiline"
    :model="localForm"
    :rules="rules"
    label-position="top"
    status-icon>
    <el-form-item
      class="column is-12"
      :label="$t('views.book.form.reference-code.label')"
      prop="referenceCode">
      <el-input
        v-model="localForm.referenceCode"
        autocomplete="referenceCode"
        :placeholder="$t('views.book.form.reference-code.placeholder')"
        @blur="handleBlur" />
    </el-form-item>
    <el-form-item
      class="column is-12"
      :label="$t('views.book.form.agent-remarks.label')"
      prop="remarks">
      <el-input
        v-model="localForm.remarks"
        type="textarea"
        :placeholder="$t('views.book.form.agent-remarks.placeholder')"
        :autosize="{ minRows: 3, maxRows: 5 }"
        @blur="handleBlur" />
    </el-form-item>
  </el-form>
</template>

<script>
import formMixin from './mixins/form'

export default {
  name: 'CheckoutFormAgentForm',
  mixins: [formMixin],
  props: {
    value: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      localForm: {
        referenceCode: this.value.referenceCode,
        remarks: this.value.remarks
      },
      rules: {
        referenceCode: [
          {
            type: 'string',
            message: this.$t('views.book.form.reference-code.invalid'),
            trigger: [
              'change',
              'blur'
            ],
            pattern: /[a-z0-9_-]/ig
          }
        ],
        remarks: [
          {
            type: 'string',
            max: 250,
            message: this.$t('views.book.form.agent-remarks.invalid'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
