<template>
  <div>
    <div>
      <!--      <div class="is-background-image" style="height: 120px;" v-lazy:background-image="property.heroImage.url"></div>-->
      <!--      <PpGap size="small" />-->
      <div class="list is-gapless is-narrow is-topless is-align-flex-start is-bottomless">
        <div class="list-content has-line-height-1-25">
          <StarRatings
            :ratings="property.starRating"
            class="is-inline-block is-size-7" />
          <h4 class="is-size-5 has-text-primary has-text-weight-bold has-line-height-1-125">
            <span style="letter-spacing: -0.5px; vertical-align: middle;">{{ property.name }}&nbsp;</span>
          </h4>
          <PropertyAddress
            :location="property.location"
            class="is-size-7"
            map-link />
        </div>
        <div class="list-action">
          <TrustYouRating
            v-bind="property.trustYou"
            lite
            :condensed="true"
            size="s" />
        </div>
      </div>
    </div>
    <ZuDivider />
    <div class="list is-gapless is-narrow is-topless is-bottomless">
      <div class="list-content">
        {{ $t('views.CheckoutFormSummary.check-in-date') }}
      </div>
      <div class="list-action">
        <span class="has-text-weight-bold">{{ searchQuery.checkInDate | date }}</span>
      </div>
    </div>
    <div class="list is-gapless is-narrow is-topless">
      <div class="list-content">
        {{ $t('views.CheckoutFormSummary.check-out-date') }}
      </div>
      <div class="list-action has-text-right">
        <span class="has-text-weight-bold">{{ searchQuery.checkOutDate | date }}</span>
      </div>
    </div>
    <div class="list is-gapless is-narrow is-topless">
      <div class="list-content">
        {{ $t('views.CheckoutFormSummary.adults') }}
      </div>
      <!--      <div class="list-middle">-->
      <!--        {{$t('views.CheckoutFormSummary.x')}}-->
      <!--      </div>-->
      <div class="list-action">
        <span class="has-text-weight-bold">{{ searchQuery.adultCount }}</span>
      </div>
    </div>
    <div class="list is-gapless is-narrow is-topless is-bottomless">
      <div class="list-content">
        {{ $t('views.CheckoutFormSummary.children') }}
        <span
          v-show="children.length > 0"
          class="list-label">(Ages: {{ searchQuery.children || 0 }})</span>
      </div>
      <!--      <div class="list-middle">-->
      <!--        {{$t('views.CheckoutFormSummary.x')}}-->
      <!--      </div>-->
      <div class="list-action">
        <span class="has-text-weight-bold">{{ children.length || 0 }}</span>
      </div>
    </div>
    <ZuDivider />
    <div class="list is-gapless wrap">
      <div class="list-content">
        <div class="has-text-grey-light">
          {{ $t('views.CheckoutFormSummary.room-description') }}
        </div>
        <div><span class="has-text-primary is-size-5 has-text-weight-bold">{{ pckg.supplierDescription | truncate }}</span></div>
      </div>
      <div
        class="list-action has-text-right"
        style="padding-right: 0;">
        <span class="has-text-weight-bold is-size-5">
          {{ pckg.adjustedDisplayRate | currencyObj({ factionDigits: 2,excludeTax: false}) }}
        </span>
        <div class="has-text-grey-light is-size-7">
          {{ $t('views.CheckoutFormSummary.per') }}
        </div>
      </div>
      <div class="list-content list-fullwidth">
        <div
          v-if="$isFoodCodeValid(pckg.foodCode)"
          class="is-size-7">
          - {{ pckg.foodCode | foodCode }}
        </div>
        <PpGap size="small" />
        <div
          v-if="pckg.cancellationPolicy && pckg.cancellationPolicy.freeCancellationBefore"
          class="refundable">
          <i class="ap-icon-check icon-size has-text-success" />
          <div class="has-text-success">
            {{ $t('components.Packages.Refundable') }}
          </div>
          <div class="has-text-grey-light is-size-7">
            <span>{{ $t('components.CancellationParse.free') }}</span>&nbsp;<span>{{ pckg.cancellationPolicy.freeCancellationBefore | dateTz({ tz: $store.state.app.timezone, isBeforeMidnight: true }) }} </span>
          </div>
          <div
            v-if="paymentType === 'payLater'"
            class="has-text-grey-light is-size-7">
            <span class="has-text-danger">
              {{ `${ $t('pay-before',{date:$options.filters.dateTz(pckg.payLaterDueDate, { tz: 'Africa/Johannesburg' })})}` }}
            </span>
          </div>
        </div>
        <p
          v-else
          class="is-flex"
          style="align-items: center;">
          <span
            class="has-text-danger icon-size m-r-2x"
            style="font-weight: 900;font-size: 1.25em;line-height: 1;">!</span>
          <span class="has-text-danger">{{ $t('components.Packages.Non-refundable') }}</span>
        </p>
      </div>
    </div>
    <PpGap />
    <div class="list is-gapless is-narrow is-topless ">
      <div class="list-content">
        Agent Markup
      </div>
      <div class="list-action has-text-right">
        <span class="has-text-weight-bold">{{ pckg.agentMarkupAmount | currencyObj }}</span>
      </div>
    </div>
    <div class="list is-gapless is-narrow is-topless ">
      <div class="list-content">
        {{ $t('views.CheckoutFormSummary.nights') }}
      </div>
      <div class="list-action has-text-right">
        <span class="has-text-weight-bold">{{ searchQuery.checkInDate | countDays({ endDate: searchQuery.checkOutDate, round: 'down'}) }}</span>
      </div>
    </div>
    <div class="list is-gapless is-narrow is-topless is-bottomless">
      <div class="list-content">
        {{ $t('views.CheckoutFormSummary.room') }}
      </div>
      <div class="list-action">
        <span class="has-text-weight-bold">{{ searchQuery.roomCount }}</span>
      </div>
    </div>
    <ZuDivider />
    <transition name="view-fade">
      <FinalAdjustment v-if="!isLoading && paymentType !== 'pay_at_hotel'" />
    </transition>
    <div v-if="paymentType === 'creditcard' && pckg.showAdjustments">
      <div class="list is-gapless is-narrow">
        <div
          class="list-content"
          style="align-self: flex-start;">
          <span class="has-text-weight-bold">{{ $t('views.search.price') }}</span>
          <div class="has-text-grey-light is-size-7">
            {{ $t('views.CheckoutFormSummary.include') }}
            <el-tooltip popper-class="include-info">
              <div slot="content">
                {{ $t('views.CheckoutFormSummary.include-info') }}
              </div>
              <i class="el-icon-info has-text-secondary" />
            </el-tooltip>
          </div>
        </div>
        <div class="list-action has-text-right">
          <span class="has-text-weight-bold is-size-5">{{ pckg.agentDisplayRate | currencyObj }}</span>
        </div>
      </div>
      <div
        v-if="pckg.finalAdjustments.length"
        class="list is-gapless is-narrow">
        <div
          class="list-content"
          style="align-self: flex-start;">
          <span>{{ $t('views.book.Transaction fee') }}</span>
        </div>
        <div class="list-action has-text-right">
          <span class="has-text-weight-bold">{{ txFeeAmount | currencyObj }}</span>
        </div>
      </div>
      <ZuDivider />
    </div>
    <div class="list is-gapless is-narrow">
      <div
        class="list-content"
        style="align-self: flex-start;">
        <span class="has-text-weight-bold has-text-primary is-size-5">{{ $t('views.CheckoutFormSummary.total-amount') }}</span>
        <div
          v-if="paymentType!=='creditcard'"
          class="has-text-grey-light is-size-7">
          {{ $t('views.CheckoutFormSummary.include') }}
        </div>
      </div>
      <div class="list-action has-text-right">
        <span
          v-if="paymentType==='creditcard'"
          class="has-text-weight-bold has-text-primary is-size-4">{{ totalChargeAmountLocal.value | currency({currency: pckg.totalAmount.currency,factionDigits: 0}) }}</span>
        <span
          v-else
          class="has-text-weight-bold has-text-primary is-size-4">{{ totalChargeAmountLocal | currencyObj }}</span>
      </div>
    </div>

    <div
      v-if="paymentType==='pay_at_hotel'"
      style="margin-top:10px">
      <div class="has-text-weight-semibold is-size-6-5">
        $0.00 due today. Credit
        Card will be charged upon the guest check-in.
      </div>
    </div>

    <div
      v-if="pckg.totalAmount"
      v-show="pckg.totalAmount.currency !== pckg.totalChargeAmount.currency"
      class="list is-gapless is-narrow">
      <p class="has-text-danger is-size-7">
        {{ $t('views.CheckoutFormSummary.charged-in') }}
      </p>
    </div>
    <div
      v-if="pckg.hotelFees"
      class="hotelfees-warpper">
      <ZuDivider />
      <div class="list is-gapless">
        <div class="list-title has-text-weight-bold">
          {{ $t('views.CheckoutFormSummary.taxes-property') }}
        </div>
      </div>
      <div
        v-for="(val, key) in pckg.hotelFees"
        :key="key"
        class="list is-gapless is-narrow is-topless is-small">
        <div class="list-content">
          {{ key }}
        </div>
        <div class="list-action">
          <span class="has-text-weight-bold">{{ val | currencyObj }}</span>
        </div>
      </div>
      <p class="is-size-7">
        {{ $t('views.CheckoutFormSummary.desc') }}
      </p>
    </div>
    <div class="m-b-6x" />
    <div class="card-contact">
      <p class="m-b">
        {{ $t('views.CheckoutFormSummary.need-help') }}
      </p>
      <p>
        <b>Email us:</b><a
          :href="`mailto:${$store.state.zumata.email}`"
          class="has-text-secondary m-r-2x">{{ $store.state.zumata.email }}</a>
      </p>
    </div>
  </div>
</template>

<script>
import StarRatings from '@/components/StarRatings'
import CancellationParse from '@/components/CancellationParse'
import TrustYouRating from '@/components/TrustYou/TrustYouRating'
import PropertyAddress from '@/components/PropertyAddress'
import FinalAdjustment from '../../components/FinalAdjustment'

export default {
  name: 'CheckoutFormSummary',
  components: {
    FinalAdjustment,
    [StarRatings.name]: StarRatings,
    [CancellationParse.name]: CancellationParse,
    [PropertyAddress.name]: PropertyAddress,
    [TrustYouRating.name]: TrustYouRating
  },
  props: {
    searchQuery: Object,
    pckg: Object,
    property: Object,
    paymentType: {
      type: String,
      default: 'balance'
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'pay-before': 'Payment due before {date} SAST'
      }
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    children () {
      return this.searchQuery.children ? this.searchQuery.children.split(',') : 0
    },
    totalAmountAfterAdjustment () {
      return this.$store.getters['bookingPolicy/totalAmountAfterAdjustment']
    },
    totalAmount () {
      return this.$store.getters['bookingPolicy/totalAmount']
    },
    txFeeAmount () {
      return this.$store.getters['bookingPolicy/txFeeAmount']
    },
    totalChargeAmountLocal () {
      return this.$store.getters['bookingPolicy/totalChargeAmountLocal']
    },
    finalAdjustmentForm () {
      return this.$store.state.bookingPolicy.finalAdjustmentForm
    },
    userInputAdjustment () {
      return this.$store.state.bookingPolicy.userInputAdjustment
    },
    totalEquivalent () {
      if (this.$store.state.bookingPolicy.totalEquivalent.value < 1) return 0
      return this.$options.filters.currency(this.$store.state.bookingPolicy.totalEquivalent.value, { currency: this.$store.state.bookingPolicy.totalEquivalent.currency })
    }
  },
  watch: {
    totalAmountAfterAdjustment (nV) {
      if (nV && nV.currency !== 'USD' && this.totalAmountAfterAdjustment.currency !== '') {
        this.convertAmount()
      }
    },
    totalAmount (nv) {
      if (nv && nv.currency && nv.currency !== this.finalAdjustmentForm.currency) {
        if (!this.finalAdjustmentForm.amount || this.finalAdjustmentForm.type === 'PERCENTAGE') {
          // this.finalAdjustmentForm.currency = nv.currency
          this.$store.commit('bookingPolicy/SET_ADJUSTMENT_FORM', {
            amount: nv.amount,
            currency: nv.currency
          })
        } else {
          if (this.userInputAdjustment.currency === nv.currency) {
            this.$store.commit('bookingPolicy/SET_ADJUSTMENT_FORM', {
              amount: this.userInputAdjustment.amount,
              currency: this.userInputAdjustment.currency
            })
          } else {
            this.$store.commit('SHOW_FULLSCREEN_LOADER')
            this.isLoading = true
            this.$store.dispatch('bookingPolicy/transactionConversion', {
              amount: this.finalAdjustmentForm.amount,
              baseCcy: this.finalAdjustmentForm.currency,
              targetCcy: nv.currency
            }).then(resp => {
              this.$store.commit('bookingPolicy/SET_ADJUSTMENT_FORM', {
                amount: resp.outlets.convertedAmount.value,
                currency: resp.outlets.convertedAmount.currency
              })
              this.$store.commit('HIDE_FULLSCREEN_LOADER')
              this.isLoading = false
            })
          }
        }
      }
    }
  },
  created () {
    if (this.pckg.agentTotalChargeAmount.currency !== 'USD') {
      this.convertAmount()
    }
  },
  methods: {
    convertAmount () {
      if (this.totalAmountAfterAdjustment.amount !== 0) {
        this.$store.dispatch('bookingPolicy/transactionConversion', {
          amount: this.totalChargeAmountLocal.value,
          baseCcy: this.totalChargeAmountLocal.currency,
          targetCcy: 'USD'
        }).then(({ outlets }) => {
          this.$store.commit('bookingPolicy/SET_DATA', { totalEquivalent: outlets.convertedAmount })
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .refundable {
    padding-left: 20px;
    position: relative;

    .ap-icon-check {
      position: absolute;
      top: 2px;
      left: 0;
    }
  }

  .include-info {
    max-width: 350px;
  }

</style>
