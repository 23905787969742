<template>
  <el-form
    ref="localForm"
    :model="localForm"
    :rules="rules"
    class="columns is-multiline"
    label-position="top"
    status-icon>
    <el-form-item
      :label="$t('views.book.form.first-name.label')"
      class="column is-6"
      prop="firstName">
      <el-input
        v-model="localForm.firstName"
        :placeholder="$t('views.book.form.first-name.placeholder')"
        autocomplete="firstName"
        @blur="handleBlur" />
    </el-form-item>
    <el-form-item
      :label="$t('views.customer.form.last-name.label')"
      class="column is-6"
      prop="lastName">
      <el-input
        v-model="localForm.lastName"
        :placeholder="$t('views.customer.form.last-name.placeholder')"
        autocomplete="lastName"
        @blur="handleBlur" />
    </el-form-item>
    <el-form-item
      :label="$t('views.book.form.nationality.label')"
      class="column is-12"
      prop="nationality">
      <InputNationality
        v-model.trim="localForm.nationality"
        :placeholder="$t('views.book.form.nationality.placeholder')"
        style="width: 100%"
        @input="handleBlur" />
    </el-form-item>
    <el-form-item
      :label="$t('views.book.form.remarks.label')"
      class="column is-12"
      prop="remarks">
      <el-input
        v-model="localForm.remarks"
        :placeholder="$t('views.book.form.remarks.placeholder')"
        :autosize="{ minRows: 3, maxRows: 5}"
        type="textarea"
        @blur="handleBlur" />
    </el-form-item>
  </el-form>
</template>

<script>
import { NAME_PATTERN } from '../../plugins/util'
import InputNationality from '../../components/InputNationality'
import formMixin from './mixins/form'

export default {
  name: 'CheckoutFormRoomLeadGuestForm',
  components: { InputNationality },
  mixins: [formMixin],
  props: {
    value: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      localForm: {
        nationality: this.value.nationality,
        firstName: this.value.firstName,
        lastName: this.value.lastName,
        remarks: this.value.remarks
      },
      rules: {
        firstName: [
          {
            type: 'string',
            message: this.$t('views.book.form.first-name.invalid'),
            trigger: [
              'change',
              'blur'
            ],
            pattern: NAME_PATTERN
          },
          {
            required: true,
            message: this.$t('views.book.form.first-name.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        lastName: [
          {
            type: 'string',
            message: this.$t('views.customer.form.last-name.invalid'),
            trigger: [
              'change',
              'blur'
            ],
            pattern: NAME_PATTERN
          },
          {
            required: true,
            message: this.$t('views.customer.form.last-name.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        nationality: [
          {
            required: true,
            message: this.$t('views.book.form.nationality.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        remarks: [
          {
            type: 'string',
            max: 250,
            message: this.$t('views.book.form.remarks.invalid'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
