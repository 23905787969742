<template>
  <div
    :class="cssClass"
    class="card-booking-status">
    {{ label }}
  </div>
</template>
<script>
export default {
  name: 'OrderStatus',
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  /**
   * Order Status
   * 1. PROCESSING: (pre-book, haven't completed the booking, on checkout page)
   * 2. PENDING: (before getting confimation from payment gateway and suppliers)
   * 3. PAYMENT_PENDING: (before getting confimation from payment gateway)
   * 4. CONFIRMED: order confirmed
   * 5. FAILURE: (Failure message from suppliers)
   * 6. CANCELLED: Cancelled successfully
   * 7. REFUND_IN_PROGRESS: Refund In-Progress
   * 8. REFUND_FAILURE: Fail to Refund (can click on refund button again)
   */
  computed: {
    cssClass () {
      switch (this.status) {
      case 'REFUND_IN_PROGRESS':
      case 'PAYMENT_PENDING':
      case 'ON_HOLD':
      case 'AWAITING_CONFIRMATION':
      case 'PENDING':
        return 'has-background-warning'
      case 'CONFIRMED':
        return 'has-background-success'
      case 'FAILURE':
      case 'REFUND_FAILURE':
        return 'has-background-danger'
      case 'CANCELLED':
      default:
        // PROCESSING
        return 'has-background-dark'
      }
    },
    label () {
      switch (this.status) {
      case 'PENDING':
        return this.$t('views.MyBookings.status.pending')
      case 'PAYMENT_PENDING':
        return this.$t('views.MyBookings.status.payment-pending')
      case 'FAILURE':
        return this.$t('views.MyBookings.status.booking-failure')
      case 'CONFIRMED':
        return this.$t('views.MyBookings.status.confirmed')
      case 'ON_HOLD':
        return this.$t('views.MyBookings.status.on-hold')
      case 'CANCELLED':
        return this.$t('views.MyBookings.status.cancelled')
      case 'REFUND_IN_PROGRESS':
        return this.$t('views.MyBookings.status.refund-in-progress')
      case 'REFUND_FAILURE':
        return this.$t('views.MyBookings.status.refund-failure')
      case 'AWAITING_CONFIRMATION':
        return this.$t('views.MyBookings.status.awaiting-confirmation')
      default:
        // bkg-ns
        return this.$t('views.MyBookings.status.processing')
      }
    }
  }
}
</script>
