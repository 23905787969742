<template>
  <el-form
    ref="localForm"
    :model="localForm"
    :rules="rules"
    class="columns is-multiline m-b-0"
    label-position="top"
    status-icon>
    <el-form-item
      :label="$t('views.book.form.first-name.label')"
      class="column is-6"
      prop="firstName">
      <el-input
        v-model="localForm.firstName"
        :placeholder="$t('views.book.form.first-name.placeholder')"
        autocomplete="firstName"
        @blur="handleBlur" />
      <p class="is-size-7 m-t has-text-grey">
        {{ $t('views.book.form.first-name.desc') }}
      </p>
    </el-form-item>
    <el-form-item
      :label="$t('views.customer.form.last-name.label')"
      class="column is-6"
      prop="lastName">
      <el-input
        v-model="localForm.lastName"
        :placeholder="$t('views.customer.form.last-name.placeholder')"
        autocomplete="lastName"
        @blur="handleBlur" />
    </el-form-item>
    <!--<el-form-item
      :label="$t('views.customer.form.country-code.label')"
      class="column is-6"
      prop="telCountryCode">
      <InputNationality
        v-model.trim="localForm.telCountryCode"
        :placeholder="$t('views.customer.form.country-code.placeholder')"
        display-type="ph"
        style="width: 100%"
        @input="handleTelCountryCodeChange"/>
    </el-form-item>
    <el-form-item
      :label="$t('views.book.form.contact-number.label')"
      class="column is-6"
      prop="contactNo">
      <el-input
        v-model.trim="localForm.contactNo"
        :placeholder="$t('views.book.form.contact-number.placeholder')"
        type="tel"
        autocomplete="contactNo"
        @blur="handleBlur"/>
    </el-form-item>
    <el-form-item
        :label="$t('views.customer.form.email.label')"
        class="column is-12"
        prop="email">
      <el-input
          v-model.trim="localForm.email"
          :placeholder="$t('views.customer.form.email.placeholder')"
          autocomplete="email"
          @blur="handleBlur"/>
    </el-form-item>-->
    <el-form-item
      :label="$t('views.book.form.nationality.label')"
      class="column is-12"
      prop="nationality">
      <InputNationality
        v-model.trim="localForm.nationality"
        :placeholder="$t('views.book.form.nationality.placeholder')"
        style="width: 100%"
        @input="handleBlur" />
      <div
        v-if="isDiffSourceMarket"
        class="diff-source-market">
        <div class="des">
          <p class="is-size-7">
            {{ $t('sourceMarket.help') }}
          </p>
        </div>
        <div class="btn">
          <slot />
        </div>
      </div>
    </el-form-item>
    <el-form-item
      :label="$t('views.book.form.remarks.label')"
      class="column is-12"
      prop="remarks">
      <el-input
        v-model="localForm.remarks"
        :placeholder="$t('views.book.form.remarks.placeholder')"
        :autosize="{ minRows: 3, maxRows: 5}"
        type="textarea"
        @blur="handleBlur" />
    </el-form-item>
  </el-form>
</template>

<script>
import { NAME_PATTERN } from '../../plugins/util'
import InputNationality from '../../components/InputNationality'
import formMixin from './mixins/form'
import { mapState } from 'vuex'

export default {
  name: 'CheckoutFormPrimaryRoomForm',
  components: { InputNationality },
  mixins: [formMixin],
  props: {
    value: {
      type: Object,
      default: undefined
    }
  },
  data () {
    return {
      isDiffSourceMarket: false,
      localForm: {
        nationality: this.value.nationality,
        firstName: this.value.firstName,
        lastName: this.value.lastName,
        remarks: this.value.remarks
        /* telCountryCode: this.value.telCountryCode,
        contactNo: this.value.contactNo,
        email: this.value.email */
      },
      rules: {
        firstName: [
          {
            type: 'string',
            message: this.$t('views.book.form.first-name.invalid'),
            trigger: [
              'change',
              'blur'
            ],
            pattern: NAME_PATTERN
          },
          {
            required: true,
            message: this.$t('views.book.form.first-name.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        lastName: [
          {
            type: 'string',
            message: this.$t('views.customer.form.last-name.invalid'),
            trigger: [
              'change',
              'blur'
            ],
            pattern: NAME_PATTERN
          },
          {
            required: true,
            message: this.$t('views.customer.form.last-name.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        /* email: [
          {
            type: 'email',
            message: this.$t('views.customer.form.email.invalid'),
            trigger: [
              'change',
              'blur',
              'input'
            ]
          },
          {
            required: true,
            message: this.$t('views.customer.form.email.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ], */
        nationality: [
          {
            required: true,
            message: this.$t('views.book.form.nationality.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        /* telCountryCode: [
          {
            required: true,
            message: this.$t('views.customer.form.country-code.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ],
        contactNo: [
          {
            type: 'string',
            message: this.$t('views.book.form.contact-number.invalid'),
            trigger: [
              'change',
              'blur'
            ],
            pattern: /^\+?[1-9]\d{1,14}$/ig
          },
          {
            required: true,
            message: this.$t('views.book.form.contact-number.required'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ], */
        remarks: [
          {
            type: 'string',
            max: 250,
            message: this.$t('views.book.form.remarks.invalid'),
            trigger: [
              'change',
              'blur'
            ]
          }
        ]
      }
    }
  },
  computed: {
    ...mapState('meta', ['sourceMarket'])
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler (nv) {
        this.localForm.nationality = this.value.nationality
      }
    },
    'localForm.nationality': {
      immediate: true,
      deep: true,
      handler (nv) {
        this.checkSourceMarket()
      }
    },
    isDiffSourceMarket (value) {
      this.$emit('nationality-change', value)
    }
  },
  methods: {
    checkSourceMarket () {
      this.isDiffSourceMarket = this.localForm.nationality && this.localForm.nationality.a2 && (this.localForm.nationality.a2 !== this.sourceMarket)
    }
  }
}
</script>

<style lang="scss" scoped>
.diff-source-market {
  background: #FFEFD6;
  display: flex;
  padding: 10px;
  margin-top: 5px;
  align-items: center;
  .des {
    line-height: 1.5;
  }
  .btn {
    margin-left: 1.5rem;
  }
}
</style>
