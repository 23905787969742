<template>
  <div class="book-page">
    <div class="section">
      <div class="container">
        <!--breadcrumb start -->
        <div class="columns m-b-2x">
          <div class="column is-12 is-mobile">
            <PpBreadcrumb
              :routes="breadCrumb"
              class="has-text-weight-bold search-one--breadcrumb is-inline" />
            <span class="has-text-secondary has-text-weight-bold">{{ $t('views.breadcrumb.Book') }}</span>
          </div>
        </div>
        <!--breadcrumb end -->
        <h2 class="title is-size-3 has-text-primary m-b-5x">
          {{ $t('views.book.title') }}
        </h2>
        <div class="columns">
          <div class="column is-8">
            <CountDown
              v-if="loaded"
              class="m-b-2x" />
            <div class="columns">
              <div class="column is-12">
                <div class="form-title m-t-0">
                  {{ $t('views.book.Room') }} 1 ({{ $t('views.book.primary guest') }})
                </div>
              </div>
            </div>
            <CheckoutFormPrimaryRoomForm
              v-if="primaryRoomForm"
              ref="primaryRoomForm"
              v-model="primaryRoomForm"
              @nationality-change="handleNationalityChange">
              <SearchBySourceMarket
                v-if="primaryRoomForm.nationality && primaryRoomForm.nationality.a2"
                ref="searchBySourceMarket"
                :currency="$route.query.currency"
                :back-url="backUrl"
                :search-params="searchParams"
                :search-query="searchQuery"
                :source-market="primaryRoomForm.nationality.a2"
                @change="handleTokenChanged" />
            </CheckoutFormPrimaryRoomForm>
            <div v-if="secondaryRoomForms.length > 0">
              <div
                v-for="room in secondaryRoomCount"
                :key="'room_' + room + 1">
                <div class="columns">
                  <div class="column is-12">
                    <div class="form-title">
                      {{ $t('views.book.Room') }} {{ room + 1 }}
                    </div>
                  </div>
                </div>
                <CheckoutFormRoomLeadGuestForm
                  ref="secondaryRoomForms"
                  v-model="secondaryRoomForms[room - 1]" />
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <div class="form-title">
                  {{ $t('views.book.agent-form-title') }}
                </div>
              </div>
            </div>
            <CheckoutFormAgentForm
              ref="agentForm"
              v-model="agentForm" />
            <div class="columns">
              <div class="column is-12">
                <div class="form-title">
                  Transfer
                </div>
              </div>
            </div>
            <RequestTransferForm
              ref="transferForm"
              v-model="transferForm" />
            <hr>
            <div class="m-b-2x">
              {{ $t('views.book.desc-1') }}
              <br>
              {{ $t('views.book.desc-2') }}
              <router-link
                to="/terms-of-use"
                target="_blank"
                class="has-text-secondary">
                {{ $t('views.book.desc-3') }}
              </router-link>.
            </div>
            <div class="m-b-8x">
              <p>
                🌳 <span class="ml-1 has-text-bold">We plant a tree for every reservation.<span /></span>
              </p>
            </div>
            <div class="has-text-right">
              <el-button
                :loading="isSubmitting"
                class="button-secondary"
                style="min-width: 155px;"
                @click="handleSubmit">
                {{ paymentType === 'payLater' ? $t('hold-booking'):$t('views.book.submit') }}
              </el-button>
              <p
                v-if="paymentType === 'payLater'"
                class="has-text-danger m-t-3x"
                v-html="$t('pay-before',{date:$options.filters.dateTz(pckg.payLaterDueDate, { tz: 'Africa/Johannesburg' })})" />
            </div>
          </div>
          <div
            v-if="!isFetching"
            class="column is-4">
            <CheckoutFormSummary
              v-if="Object.keys(pckg).length"
              :search-query="searchQuery"
              :property="property"
              :pckg="pckg"
              :payment-type="paymentType"
              class="summary-component-content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
import CheckoutFormSummary from './CheckoutFormSummary'
import CheckoutFormPrimaryRoomForm from './CheckoutFormPrimaryRoomForm'
import CheckoutFormRoomLeadGuestForm from './CheckoutFormRoomLeadGuestForm'
import CheckoutFormAgentForm from './CheckoutFormAgentForm'
import smoothScroll from '@/plugins/smoothScroll'
import CountDown from '@/components/CountDown/index'
import { getCountryByAlpha2 } from '@/plugins/countriesHelper'
import { retrieveAgent } from '@/service/auth'
import SearchBySourceMarket from '@/components/SearchBySourceMarket'
import RequestTransferForm from './RequestTransferForm'

const reserveForm = (payload) => {
  return store.dispatch('bookingPolicy/reserveForm', payload)
}

const ERROR_INVALID_FORM = 'invalid_form'

export default {
  name: 'Book',
  components: {
    SearchBySourceMarket,
    CheckoutFormAgentForm,
    CheckoutFormRoomLeadGuestForm,
    CheckoutFormPrimaryRoomForm,
    CheckoutFormSummary,
    CountDown,
    RequestTransferForm
  },
  i18n: {
    messages: {
      'en-US': {
        'hold-booking': 'Guarantee and Hold',
        'pay-before': 'Please pay before {date} SAST. We will send you reminders.<br>Note: The booking will be auto-cancelled if not fully paid by this deadline.'
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    const payload = {
      query: { ...to.query, currentState: 'reserveForm' },
      ckToken: to.params.ckToken
    }
    reserveForm(payload).then(({ searchQuery }) => {
      next(vm => {
        vm.loaded = true
        let defaultNationality = getCountryByAlpha2(searchQuery.sourceMarket)
        vm.primaryRoomForm = {
          nationality: JSON.parse(JSON.stringify(defaultNationality)),
          firstName: '',
          lastName: '',
          remarks: ''
        }
        let k = 0
        // To create initial data base on roomCount
        while (k < vm.secondaryRoomCount) {
          vm.secondaryRoomForms.push({
            nationality: JSON.parse(JSON.stringify(defaultNationality)),
            firstName: '',
            lastName: '',
            remarks: ''
          })
          k++
        }
        // initial additionalGuestsRemain
        vm.additionalGuestsRemain = vm.searchQuery.adultCount > vm.searchQuery.roomCount
          ? vm.searchQuery.adultCount - vm.searchQuery.roomCount : 0
        // initial additional guest forms
        for (let i = 0; i < vm.searchQuery.roomCount; i++) {
          vm.additionalGuestForms.push([])
        }
      })
    }).catch((err) => {
      next(vm => {
        if (err && err.error && err.error.code === 'ck_token_expired') {
          vm.postBookError = {
            title: vm.$t('views.CheckoutForm.package-expired'),
            ...err.error
          }
          vm.submitModalVisibility = true
        } else {
          vm.postBookError = {
            title: '',
            message: err && err.error ? err.error.message : err.message
          }
          vm.submitModalVisibility = true
        }
      })
    })
  },
  data () {
    return {
      primaryRoomForm: {
        nationality: undefined,
        firstName: '', // ap-762 #7
        lastName: '',
        remarks: ''
        /* telCountryCode: undefined,
        contactNo: undefined,
        email: undefined */
      },
      secondaryRoomForms: [],
      additionalGuestsRemain: 0,
      additionalGuestForms: [],
      agentForm: {},
      transferForm: {
        requestTransfer: []
      },
      postBookError: undefined,
      submitModalVisibility: false,
      loaded: false, // When the sourceMarket changes, we don't want to refresh the page. Use this parameter to refresh components
      isDiffSourceMarket: false
    }
  },
  computed: {
    ...mapState('bookingPolicy', {
      availability: state => state.reserveForm.availability,
      property: state => state.reserveForm.property,
      searchQuery: state => state.reserveForm.searchQuery,
      searchParams: state => state.reserveForm.searchParams,
      backUrl: state => state.reserveForm.backUrl,
      isFetching: state => state.isLoading,
      isSubmitting: state => state.isSubmitting,
      ckToken: state => state.reserveForm.ckToken,
      finalAdjustmentForm: state => state.finalAdjustmentForm
    }),
    paymentType () {
      return this.$route.query.payType
    },
    breadCrumb () {
      return [
        {
          label: this.$t('views.breadcrumb.Find Hotel'),
          replace: true,
          route: { name: 'home' }
        },
        {
          label: this.$t('views.breadcrumb.Location'),
          replace: true,
          route: {
            name: 'search',
            params: { ...this.$store.getters['bookingPolicy/searchQueryParams'].params },
            query: {
              ...this.$store.getters['bookingPolicy/searchQueryParams'].query,
              searchId: this.$newSearchId()
            }
          }
        },
        {
          label: this.property.name,
          replace: true,
          route: { path: this.backUrl }
        }
      ]
    },
    secondaryRoomCount () {
      return this.searchQuery.roomCount - 1 || 0
    },
    pckg () {
      return this.availability.package || {}
    }
  },
  created () {
    this.$nextTick(() => {
      const forms = this.$store.state['bookingPolicy']['forms']
      if (forms) {
        this.primaryRoomForm = forms.primaryRoomForm
        this.secondaryRoomForms = forms.secondaryRoomForms
        this.additionalGuestsRemain = forms.additionalGuestsRemain
        this.additionalGuestForms = forms.additionalGuestForms
        this.agentForm = forms.agentForm
        this.transferForm = forms.transferForm
      }
      this.primaryRoomForm.nationality = getCountryByAlpha2(this.$store.state.meta.sourceMarket)

      if (this.postBookError !== undefined) {
        // CountDown components will popup error
        this.$store.commit(
          'SET_GLOBAL_MODAL',
          {
            show: true,
            content: this.postBookError.message || '',
            newSearchBtn: true,
            hotelPageBtn: true,
            okBtn: false,
            resolveActions: this.postBookError.resolveActions && this.postBookError.resolveActions.resolveActions
          },
          {
            root: true
          }
        )
      }
    })
  },
  methods: {
    handleTokenChanged ({ ckToken, currency, sourceMarket, payType }) {
      this.loaded = false
      this.$router.replace(
        {
          name: 'book',
          params: { ckToken: ckToken },
          query: {
            currency,
            sourceMarket,
            payType
          }
        }, () => {
          const payload = {
            query: { ...this.$route.query, currentState: 'reserveForm' },
            ckToken: this.$route.params.ckToken
          }
          reserveForm(payload).then(() => {
            this.loaded = true
            this.$refs.primaryRoomForm.checkSourceMarket()
          }).catch((err) => {
            const vm = this
            if (err.error && err.error.code === 'ck_token_expired') {
              vm.postBookError = {
                title: vm.$t('views.CheckoutForm.package-expired'),
                ...err.error
              }
              vm.submitModalVisibility = true
            } else if (err.error) {
              vm.postBookError = {
                title: '',
                ...err.error
              }
              vm.submitModalVisibility = true
            }
          })
        }
      )
    },
    constructContactPersonForm (contactPersonForm) {
      const { telCountryCode, contactNo } = contactPersonForm

      return {
        ...contactPersonForm,
        contactNo: `${telCountryCode}${contactNo}`
      }
    },
    constructPostBody () {
      const roomLeadGuest = { ...this.primaryRoomForm }
      const { nationality } = this.primaryRoomForm
      roomLeadGuest.nationality = nationality.a2
      // use company contact number
      const companyContactNo = retrieveAgent('contactNo')
      let companyContactObj = {}
      if (companyContactNo) {
        roomLeadGuest.telCountryCode = companyContactNo[0]
        roomLeadGuest.contactNo = companyContactNo[1]
        companyContactObj = { telCountryCode: companyContactNo[0], contactNo: companyContactNo[1] }
      }
      // roomLeadGuest.telCountryCode = telCountryCode.ph
      // normalized nationality object on secondary forms
      let normalizedSecondaryRoomForms = this.secondaryRoomForms
      if (this.secondaryRoomForms.length) {
        normalizedSecondaryRoomForms = this.secondaryRoomForms.map((obj) => {
          return {
            ...obj,
            nationality: obj.nationality.a2,
            ...companyContactObj
          }
        })
      }
      return {
        ckToken: this.ckToken,
        pckgToken: this.availability.package.token,
        roomLeadGuests: [ roomLeadGuest, ...normalizedSecondaryRoomForms ],
        contactPerson: this.constructContactPersonForm(roomLeadGuest),
        metadata: {
          agentReferenceCode: this.agentForm.referenceCode,
          agentRemarks: this.agentForm.remarks,
          requestTransfer: this.transferForm.requestTransfer
        },
        payment: {},
        agentFinalAdjustment: this.finalAdjustmentForm
      }
    },
    handleBack () {
      history.back()
    },
    async handleSubmit () {
      if (this.isDiffSourceMarket) {
        return this.$confirm(
          this.$t('sourceMarket.nationality-changed-desc'),
          this.$t('sourceMarket.nationality-changed'),
          {
            confirmButtonText: this.$t('sourceMarket.btnCheck'),
            cancelButtonText: this.$t('views.admin.Cancel'),
            cancelButtonClass: 'button-secondary-outline',
            confirmButtonClass: 'button-secondary'
          }
        ).then(() => {
          let interval = null
          let attempt = 0
          const maxAttempt = 5
          this.$nextTick(() => {
            // Thanks to https://jefrydco.id/en/blog/safe-access-vue-refs-undefined/
            interval = setInterval(() => {
              if (this.$refs.searchBySourceMarket || attempt > maxAttempt) {
                clearInterval(interval)
                this.$refs.searchBySourceMarket.handleSearch()
              }
              attempt++
            }, 100)
          })
        })
      }

      this.$store.commit('SHOW_FULLSCREEN_LOADER', 'order')
      const { secondaryRoomForms, ...rest } = this.$refs
      const v = [].concat(secondaryRoomForms, ...Object.values(rest)).filter(v => v) // v => v remove undefined vNode
      try {
        const res = await Promise.all(v.map(vNode => vNode.validateForm()))
        const invalid = res.find(a => a.valid === false)

        if (invalid) {
          smoothScroll.scrollTo('.is-error', { offset: -100 })
          throw {
            type: 'submiting_error',
            code: ERROR_INVALID_FORM
          }
        }

        this.$store.commit('bookingPolicy/SET_DATA', {
          forms: {
            primaryRoomForm: this.primaryRoomForm,
            secondaryRoomForms: this.secondaryRoomForms,
            additionalGuestsRemain: this.additionalGuestsRemain,
            additionalGuestForms: this.additionalGuestForms,
            agentForm: this.agentForm,
            transferForm: this.transferForm
          }
        })
        if (this.paymentType === 'payLater') {
          this.$store.dispatch('bookingPolicy/postBook', { ...this.constructPostBody(), payment: { paymentType: 'payLater' } }).then(({ outlets }) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.$router.push(outlets.nextStep)
          }).catch((err) => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.$store.commit('meta/SET_META_ACTION', err.meta)
            this.$store.commit(
              'SET_GLOBAL_MODAL',
              { show: true, okBtn: true, content: err.message || (err.error && err.error.message) || '' }
            )
          })
        } else {
          await this.$store.dispatch('bookingPolicy/preBook', this.constructPostBody())
          setTimeout(() => {
            this.$store.commit('HIDE_FULLSCREEN_LOADER')
            this.$router.push({
              name: 'payment',
              params: { ckToken: this.ckToken },
              query: { ...this.$route.query }
            })
          }, 1000)
        }
      } catch (err) {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')
        if (err.code !== ERROR_INVALID_FORM) {
          this.$store.commit('meta/SET_META_ACTION', err.meta)
          this.$store.commit(
            'SET_GLOBAL_MODAL',
            { show: true, content: err.message || (err.error && err.error.message) || '' }
          )
        }
      }
    },
    handleNationalityChange (isDiffSourceMarket) {
      this.isDiffSourceMarket = isDiffSourceMarket
    }
  },
  metaInfo () {
    return {
      title: 'Guest Details'
    }
  }
}
</script>

<style scoped>
  .ml-1 {
    margin-left: 0.25rem;
  }
</style>
