<template>
  <el-form
    ref="localForm"
    :inline="true"
    label-position="top"
    :model="localForm"
    status-icon
    style="text-align: center;">
    <div v-loading="!iframeIsReady">
      <div
        v-if="callbackData&&callbackData.success===false && !isSubmitting"
        class="has-text-danger has-text-centered">
        {{ callbackData.data.reason || 'Payment Failed' }}
      </div>
      <iframe
        v-show="iframeIsReady && showIframe"
        name="creditCardFrame"
        scrolling="no"
        frameborder="0"
        :src="apiUrl"
        :style="`width: 100%;height:${height}px`"
        @load="handleIframeLoaded" />
    </div>
    <div class="button-wrapper">
      <el-button
        v-if="iframeIsReady && showIframe"
        class="button-secondary"
        style="min-width: 155px;"
        icon="el-icon-bank-card"
        @click="makePayment">
        Complete Booking
      </el-button>
    </div>
  </el-form>
</template>

<script>
const retry = function (fn, times, delay) {
  return new Promise(function (resolve, reject) {
    let attempt = function () {
      fn().then(resolve).catch(function (err) {
        if (times === 0) {
          reject(err)
        } else {
          times--
          setTimeout(function () {
            attempt()
          }, delay)
        }
      })
    }

    attempt()
  })
}

export default {
  name: 'PayAtHotelPayment',
  props: {
    paymentInfo: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data () {
    return {
      iframeIsReady: false,
      height: 500,
      valid: false,
      receiver: undefined,
      callbackData: undefined,
      showIframe: true,
      isSubmitting: false,
      localForm: {
        name: '',
        creditCard: ''
      }
    }
  },
  computed: {
    host () {
      const host = this.paymentInfo && this.paymentInfo.paymentApi.replace('/api', '')

      return host === '' ? process.env.VUE_APP_PCI_BOOKING_HOST_FALLBACK_URL : host
    },
    apiUrl () {
      // https://service.pcibooking.net/api/payments/capturecard?brand=zumatadevelopment_sandbox&language=EN
      const origin = location.protocol + '//' + window.location.host
      const successCallbackUrl = encodeURIComponent(origin + '/pci-success?cardToken={cardToken}&cardType={cardType}&cardNumber={cardNumber}&cvv={cvv}&expiration={expiration}&cardHolderName={cardHolderName}&sendMessage=true')
      const failCallbackUrl = encodeURIComponent(origin + '/pci-fail')
      const postMessageHost = encodeURIComponent(window.location.origin)
      const sessionToken = encodeURIComponent(this.paymentInfo.paymentToken)

      return this.paymentInfo && `${this.paymentInfo.paymentApi}/payments/capturecard?sessionToken=${sessionToken}&autoDetectCardType=true&cvv=true&brand=${this.paymentInfo.paymentBrand}&language=EN&failure=${failCallbackUrl}&UnavailThreeDSAuth=Accept&submitWithPostMessage=true&autoFocus=false&success=${successCallbackUrl}&postMessageHost=${postMessageHost}`
    }
  },
  mounted () {
    window.successCallBack = (data) => {
      this.isSubmitting = false
      // console.log('iframe success call back:', data)
      this.showIframe = false
      this.callbackData = {
        success: true,
        data: data
      }
    }
    window.failCallBack = (data) => {
      this.isSubmitting = false
      // console.log('iframe fail call back')
      // this.showIframe = false
      this.callbackData = {
        success: false,
        data: data
      }
      window.frames['creditCardFrame'].location = this.apiUrl
      this.$emit('paymentFailed')
    }
    this.$nextTick(() => {
      if (window.addEventListener) {
        window.addEventListener('message', this.handleFrameMessage)
      } else if (window.attachEvent) {
        window.attachEvent('message', this.handleFrameMessage)
      }
    })
  },
  beforeDestroy () {
    window.removeEventListener('message', this.handleFrameMessage)
  },
  methods: {
    handleFrameMessage (e) {
      const originDomain = e.origin.replace('http://', '').replace('https://', '').toLowerCase()
      const postMessageOrigin = this.host.replace('http://', '').replace('https://', '').toLowerCase()
      if (originDomain === postMessageOrigin) {
        if (e.data === 'ready') {
          this.iframeIsReady = true
        } else if (e.data === 'valid') {
          this.valid = true
        } else if (typeof e.data === 'string' && e.data.indexOf('frameDimensionsChanged') === 0) {
          let objectParts = e.data.split(':')
          this.height = parseInt(objectParts[2])
        } else {
          this.valid = false
        }
      }
    },
    handleIframeLoaded () {
      this.receiver = this.$el.querySelector('iframe')
    },
    handleValid () {
      this.receiver.contentWindow.postMessage('validate', this.host)
      return retry(() => {
        return new Promise((resolve, reject) => {
          if (this.valid) {
            resolve(true)
          } else {
            reject(new Error('invalid_credit_card'))
          }
        })
      }, 10, 300)
    },
    handlePayment () {
      this.isSubmitting = true
      this.callbackData = undefined
      this.receiver.contentWindow.postMessage('submit', this.host)
      return retry(() => {
        return new Promise((resolve, reject) => {
          if (this.callbackData && this.callbackData.success) {
            this.isSubmitting = false
            const { cardToken } = this.callbackData.data
            const arr = cardToken.split('/')
            this.callbackData.data.cardToken = arr[arr.length - 1]
            resolve(this.callbackData)
          } else {
            this.isSubmitting = false

            const reason = this.callbackData && this.callbackData.data && this.callbackData.data.reason

            reject(new Error(reason || 'An error occurred while processing the payment'))
          }
        })
      }, 30, 300) // Need to be longer than valid detection time
    },
    async makePayment () {
      try {
        this.$store.commit('SHOW_FULLSCREEN_LOADER')

        const isValid = await this.handleValid()

        if (isValid) {
          const payload = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_PREFIX_NAME}postBook`))
          const payAtHotelCallbackData = await this.handlePayment()

          payload.payment.paymentType = 'pay_at_hotel'
          payload.payment = { ...payload.payment, creditCardInfo: payAtHotelCallbackData.data }

          const res = await this.$store.dispatch('bookingPolicy/postBook', payload)
          const { outlets } = res
          this.$store.commit('HIDE_FULLSCREEN_LOADER')
          if (outlets.nextStep) {
            this.$router.replace({ path: outlets.nextStep })
          }
        }
      } catch (err) {
        this.$store.commit('HIDE_FULLSCREEN_LOADER')

        if (err.message === 'invalid_credit_card') {
          this.$store.commit(
            'SET_GLOBAL_MODAL',
            { show: true, content: 'Credit card provided is invalid', okBtn: true }
          )

          return
        }

        this.showIframePayment()

        this.$store.commit(
          'SET_GLOBAL_MODAL',
          { show: true, content: err.message || (err.error && err.error.message) || '', okBtn: true }
        )

        return err
      }
    },
    showIframePayment () {
      this.showIframe = true
      this.iframeIsReady = false
      window.frames['creditCardFrame'].location = this.apiUrl
    }
  }
}
</script>

<style scoped>
.button-wrapper {
  margin-bottom: 30px;
  margin-top: 20px;
  width: 100%;
}
</style>
