<template>
  <PpModal v-model="activeModal">
    <div
      class="message has-background-white"
      style="width: 597px">
      <h5 class="title is-4 has-text-secondary">
        <i class="el-icon-warning has-text-danger" />
        {{ title }}
      </h5>
      <PpGap />
      <p class="is-size-7 m-b-6x">
        {{ description }}
      </p>
      <div class="has-text-right">
        <el-button
          class="button-secondary-outline"
          style="min-width: 100px;"
          size="small"
          @click="activeModal=false">
          {{ $t('app.close') }}
        </el-button>
        <el-button
          v-if="action"
          size="small"
          style="min-width: 155px;"
          class="button-secondary"
          @click="toHotelPage">
          {{ action }}
        </el-button>
      </div>
    </div>
  </PpModal>
</template>

<script>
import moment from 'moment'
export default {
  name: 'PriceChangedPopup',
  props: {
    v3Status: {
      type: Object,
      default () {
        return null
      }
    },
    booking: {
      type: Object,
      default () {
        return null
      }
    }
  },
  i18n: {
    messages: {
      'en-US': {
        'price-change': {
          title: 'Price has changed',
          desc: 'Your payment is unsuccessful because the package price has change. Please check the new price.',
          action: 'Check new price'
        },
        'nationality-mismatch': {
          title: 'Nationality mismatch.',
          desc: 'Booking failure due to nationality mismatch, you will not be charged. Please check availability again when you change nationality.',
          action: 'Check availability'
        }
      },
      'zh-CN': {
        'price-change': {
          title: '价格已经改变',
          desc: '您的付款不成功，因为价格已更改。请核对新价格。',
          action: '检查新价格'
        },
        'nationality-mismatch': {
          title: '国籍不匹配。',
          desc: '由于国籍不匹配而导致预订失败，您将不会被收取任何费用。当您更改国籍时，请再次检查您预订房间的可用性。',
          action: '检查可用性'
        }
      },
      'zh-TW': {
        'price-change': {
          title: '價格已經改變',
          desc: '您的付款不成功，因為價格已更改。請核對新價格。',
          action: '檢查新價格'
        },
        'nationality-mismatch': {
          title: '國籍不匹配。',
          desc: '由於國籍不匹配而導致預訂失敗，您將不會被收取任何費用。當您更改國籍時，請再次檢查您預訂房間的可用性。',
          action: '檢查空房'
        }
      }
    }
  },
  data () {
    return {
      activeModal: false,
      title: null,
      description: null,
      action: null
    }
  },
  watch: {
    v3Status: {
      immediate: true,
      deep: true,
      handler (nv) {
        if (nv && nv.failure_reason) {
          switch (nv.failure_reason.code) {
          case 'ERR-BK-PRICECHANGE':
            this.title = this.$t('price-change.title')
            this.description = this.$t('price-change.desc')
            this.action = this.$t('price-change.action')

            this.activeModal = true

            break
          case 'ERR-BK-NATIONALITYMISMATCH':
            this.title = this.$t('nationality-mismatch.title')
            this.description = this.$t('nationality-mismatch.desc')
            this.action = this.$t('price-change.action')

            this.activeModal = true

            break
          default:
            break
          }
        }
      }
    }
  },
  methods: {
    toHotelPage () {
      this.activeModal = false
      if (this.booking) {
        let checkInDate = this.booking.checkInDate
        let checkOutDate = this.booking.checkOutDate
        let now = this.$options.filters.date(new Date(), 'YYYY-MM-DD')
        if (moment(now).isAfter(checkInDate)) {
          checkInDate = now
          checkOutDate = moment(now).add(1, 'days').format('YYYY-MM-DD')
        }
        this.$router.push({
          name: 'property',
          params: {
            name: this.booking.propertyName
          },
          query: {
            currency: this.booking.currency,
            checkInDate: checkInDate,
            checkOutDate: checkOutDate,
            sourceMarket: this.booking.sourceMarket,
            roomCount: this.booking.roomCount,
            adultCount: this.booking.adultCount,
            children: this.booking.childrenAges ? this.booking.childrenAges.join(',') : undefined,
            propertyCode: this.booking.propertyCode,
            searchId: this.$newSearchId()
          }
        })
      }
    }
  }

}
</script>

<style scoped>

</style>
