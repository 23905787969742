<template>
  <PpModal v-model="open">
    <PpCard class="box has-border is-flex">
      <div class="edit-profile-modal-content modal-columns-outer">
        <el-form
          ref="form"
          :model="form"
          class="columns is-multiline search-bookings-form"
          label-position="top"
          @submit.native.prevent>
          <div class="column is-12">
            <h3 class="is-size-4 has-text-secondary has-text-weight-bold">
              Add commission
            </h3>
          </div>
          <div class="column is-12">
            <div class="columns">
              <el-form-item
                label="adjustment"
                class="column is-4 hide-border-radius-right"
                prop="markupType">
                <el-select
                  v-model="form.type">
                  <el-option
                    v-for="item in typeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value" />
                </el-select>
              </el-form-item>
              <el-form-item
                class="column hide-border-radius-left"
                label=" "
                prop="amount">
                <el-input
                  v-model="form.amount"
                  v-number-input.float="{min:0, decimal:2}"
                  clearable
                  placeholder="Markup amount">
                  <span slot="suffix">{{ suffix }}</span>
                </el-input>
              </el-form-item>
            </div>
          </div>
          <!--<div class="column is-12">
            <strong>Initial price:</strong> {{ examplePrice|currencyObj }}, <strong>Price after markup:</strong> {{ examplePriceAfterAdjustment|currencyObj }}
          </div>-->
          <div class="column is-12 has-text-right">
            <el-button
              class="button-secondary-outline"
              style="min-width: 115px;"
              @click="handleCancel">
              Cancel
            </el-button>
            <el-button
              class="button-secondary"
              style="min-width: 125px;"
              native-type="button"
              @click="handleSubmit">
              Apply
            </el-button>
          </div>
        </el-form>
      </div>
    </PpCard>
  </PpModal>
</template>

<script>
import store from '@/store'

export default {
  name: 'FinalAdjustmentForm',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      typeOptions: [
        {
          label: 'Percentage',
          value: 'PERCENTAGE'
        },
        {
          label: 'Amount',
          value: 'AMOUNT'
        }
      ],
      form: {
        type: '',
        amount: '',
        currency: ''
      }
    }
  },
  computed: {
    examplePrice () {
      return store.getters['bookingPolicy/examplePriceObj']
    },
    examplePriceAfterAdjustment () {
      return store.getters['bookingPolicy/examplePriceAfterAdjustment'](this.form)
    },
    finalAdjustmentForm () {
      return store.state.bookingPolicy.finalAdjustmentForm
    },
    /* type: {
      get () {
        return store.state.bookingPolicy.finalAdjustmentForm.type
      },
      set (nV) {
        store.commit('bookingPolicy/SET_ADJUSTMENT_FORM', {
          type: nV
        })
      }
    },
    amount: {
      get () {
        return store.state.bookingPolicy.finalAdjustmentForm.amount
      },
      set (nV) {
        store.commit('bookingPolicy/SET_ADJUSTMENT_FORM', {
          amount: nV
        })
      }
    }, */
    suffix () {
      console.log(this.type)
      return this.form.type === 'PERCENTAGE' ? '%' : this.form.currency
    }
    /* form () {
      return {
        type: this.type,
        amount: this.amount
      }
    } */
  },
  watch: {
    open: {
      immediate: true,
      handler (nv) {
        this.form.type = store.state.bookingPolicy.finalAdjustmentForm.type
        this.form.amount = store.state.bookingPolicy.finalAdjustmentForm.amount
        const totalAmount = store.getters['bookingPolicy/totalAmount']
        this.form.currency = totalAmount ? totalAmount.currency : ''
      }
    }
  },
  methods: {
    handleSubmit () {
      store.commit('bookingPolicy/SET_ADJUSTMENT_FORM', {
        ...this.form
      })
      store.commit('bookingPolicy/SET_USER_INPUT_ADJUSTMENT', {
        ...this.form
      })
      this.handleClose()
    },
    handleClose () {
      this.$emit('update:open', false)
    },
    handleCancel () {
      this.$refs.form.resetFields()
      this.handleClose()
    }
  }
}
</script>

<style lang="scss" scoped>
    ::v-deep .el-input__prefix {
      padding-left: 10px;
      padding-right: 0;
    }
    ::v-deep .el-input__suffix {
      line-height: 40px;
    }
</style>
