<template>
  <h1 class="title is-size-3 m-b has-text-white">
    <i
      v-if="status === 'CONFIRMED' || status === 'ON_HOLD'"
      class="ap-icon-check has-text-white" />
    <i
      v-if="status === 'FAILURE' || status === 'REFUND_FAILURE'"
      class="ap-icon-close has-text-danger" />
    {{ label }}
  </h1>
</template>
<script>
export default {
  name: 'OrderMessage',
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  /**
   * Order Status
   * 1. PROCESSING: (pre-book, haven't completed the booking, on checkout page)
   * 2. PENDING: (before getting confimation from payment gateway and suppliers)
   * 3. PAYMENT_PENDING: (before getting confimation from payment gateway)
   * 4. CONFIRMED: order confirmed
   * 5. FAILURE: (Failure message from suppliers)
   * 6. CANCELLED: Cancelled successfully
   * 7. REFUND_IN_PROGRESS: Refund In-Progress
   * 8. REFUND_FAILURE: Fail to Refund (can click on refund button again)
   * 9. ON_HOLD
   */
  computed: {
    label () {
      switch (this.status) {
      case 'FAILURE':
        return this.$t('views.book.PaymentFailed.title')
      case 'CONFIRMED':
        return this.$t('views.book.PaymentConfirmation.title')
      case 'CANCELLED':
        return this.$t('views.book.PaymentCancelled.title')
      case 'REFUND_IN_PROGRESS':
        return this.$t('views.book.PaymentRefunding.title')
      case 'REFUND_FAILURE':
        return this.$t('views.book.PaymentRefundFailure.title')
      case 'ON_HOLD':
        return this.$t('views.book.PaymentOnHold.title')
      case 'AWAITING_CONFIRMATION':
        return this.$t('views.book.PaymentConfirmation.title')
      default:
        return this.$t('views.book.PaymentProcessing.title')
      }
    }
  }
}
</script>
