<template>
  <span>
    <el-button
      class="button-secondary"
      @click="handleSearch">{{ $t('sourceMarket.btnCheck') }}</el-button>
    <PpModal
      v-model="isModalActive"
      :is-loading="isSearching"
      :class="{hasPackages:!!availability.packages}"
      :loading-message="$t('PpModal.requesting')">
      <PpCard class="box is-flex">
        <template slot="head">
          <div
            v-if="availability.packages"
            class="has-text-weight-bold has-text-dark">{{ $t('sourceMarket.title') }}</div>
          <div
            v-else
            class="has-text-weight-bold has-text-dark">{{ $t('sourceMarket.titleNone') }}</div>
        </template>
        <div
          v-if="availability.packages"
          class="package-list-content">
          <PackagesList
            v-if="availability.search || isSearching"
            :search-id="searchId"
            :property-code="searchQuery.propertyCode"
            :is-loading="isSearching"
            :selected-pckg-idx="selectedPckgIdx"
            :packages="availability.packages"
            :total-items="totalItems"
            :is-searching-more="isSearchingMore"
            @more="handleMore">
            <Packages
              slot-scope="{ pckg, idx}"
              :pckg="pckg"
              :adult-count="adultCount"
              :children-count="childrenCount">
              <el-button
                slot="call-to-action"
                class="button-secondary"
                style="width: 130px;"
                @click="handleSelectedPackage(pckg, idx, true)">{{ $t('views.SearchOne.select') }}</el-button>
              <el-button
                slot="call-to-action-noRefundAble"
                class="button-secondary"
                style="width: 130px;"
                @click="handleSelectedPackage(pckg, idx, false)">{{ $t('views.SearchOne.select') }}</el-button>
            </Packages>
          </PackagesList>
        </div>
        <div
          v-else
          class="none-package has-text-grey-light is-size-7">
          {{ $t('sourceMarket.contentNone') }}
        </div>
        <template slot="foot">
          <PpGap size="small" />
          <div class="list is-gapless">
            <div class="list-content" />
            <div class="list-action">
              <el-button
                type="outline"
                @click="isModalActive=false">
                {{ $t('sourceMarket.btnCancel') }}
              </el-button>
              <el-button
                type="outline"
                @click="goBack">
                {{ $t('sourceMarket.btnBackToSearch') }}
              </el-button>
            </div>
          </div>
        </template>
      </PpCard>
    </PpModal>
    <BookingPolicyPopup
      v-if="bookingPolicyModalVisibility"
      :nights="nights"
      :property="property"
      :selected-pckg="selectedPckg"
      :search-id="searchId"
      :refund-able="selectedRefundAble"
      :currency="currency"
      :search-object="availability.search"
      :location-query="searchQuery.name"
      :region-id="searchQuery.regionId"
      :source-market="sourceMarket"
      @close="toggleBookingPolicyModal(false)"
      @fetchHotelPackages="handleSearch"
      @next="handleReserveNow" />
  </span>
</template>

<script>
import Packages from '@/components/Packages'
import PackagesList from '@/components/PackagesList'
import BookingPolicyPopup from '@/components/BookingPolicyPopup'
import { mapState } from 'vuex'
import smoothScroll from '@/plugins/smoothScroll'

export default {
  name: 'SearchBySourceMarket',
  components: {
    BookingPolicyPopup,
    PackagesList,
    Packages
  },
  props: {
    searchParams: {
      type: Object,
      required: true
    },
    currency: {
      type: String,
      required: true
    },
    searchQuery: {
      type: Object,
      required: true
    },
    backUrl: {
      type: String,
      required: true
    },
    sourceMarket: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isModalActive: false,
      bookingPolicyModalVisibility: false,
      selectedPckgIdx: 0,
      selectedPckg: {},
      searchId: undefined,
      selectedRefundAble: false,
      searchPageTimeOut: false
    }
  },
  computed: {
    ...mapState('searchOne', [
      'property',
      'availability',
      'reviews',
      'isSearching',
      'fetchCompleted',
      'isSearchingMore',
      'nights'
    ]),
    ...mapState('bookingPolicy', [
      'nextStep',
      'bookingPolicy'
    ]),
    totalItems () {
      return this.availability.pagination ? this.availability.pagination.totalItems : 0
    },
    property () {
      return this.$store.state.searchOne.property || { name: this.searchParams.name }
    },
    adultCount () {
      return this.availability.search ? this.availability.search.adult_count : 0
    },
    childrenCount () {
      return this.availability.search && this.availability.search.children ? this.availability.search.children.length : 0
    }
  },
  methods: {
    handleSearch () {
      this.isModalActive = true
      this.searchId = this.$newSearchId()
      const newQuery = {
        ...this.searchQuery,
        ...this.searchParams,
        searchId: this.searchId,
        sourceMarket: this.sourceMarket,
        currency: this.currency
      }
      const { regionName, ...query } = newQuery
      this.$store.commit('searchMany/SET_QUERY_PARAMS', newQuery)
      this.$store.commit(
        'searchOne/SET_SEARCH_ONE',
        {
          fetchCompleted: false,
          availability: { results: [] }
        }
      )
      this.$store.dispatch('searchOne/search', query)
      smoothScroll.scrollTo('#pckgslist')
    },
    async handleSelectedPackage (pckg, idx, refundAble) {
      this.selectedPckgIdx = idx
      this.selectedPckg = pckg
      this.selectedRefundAble = refundAble
      setTimeout(() => {
        this.toggleBookingPolicyModal(true)
      }, 380)
    },
    handleReserveNow (nextStep) {
      this.$emit('change', {
        ckToken: nextStep.ckToken,
        currency: this.currency,
        sourceMarket: this.sourceMarket,
        payType: nextStep.payType
      })
      this.bookingPolicyModalVisibility = false
    },
    toggleBookingPolicyModal (value) {
      this.isModalActive = false
      this.bookingPolicyModalVisibility = value
      if (!value) {
        this.selectedPckg = {}
      }
    },
    goBack () {
      this.$router.push({ path: this.backUrl })
    },
    handleMore () {
      let { page } = JSON.parse(JSON.stringify(this.availability.pagination))
      this.$store.dispatch('searchOne/searchMore', { ...this.query, page: ++page, limit: 6 })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/bulma-variables";

.package-list-content {
  margin: 5px 2px;
  @include tablet {
    min-width: 700px;
  }
}

::v-deep .hasPackages .pp-modal--content {
  @include tablet {
    min-width: 750px;
  }
}
::v-deep .pp-modal--content {
  line-height: 1.5;
}

</style>
