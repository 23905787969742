export default {
  watch: {
    'value' (nV, oV) {
      this.localForm = nV
    }
  },
  methods: {
    validateForm () {
      return new Promise((resolve, reject) => {
        this.$refs.localForm.validate((valid, fields) => {
          resolve({
            valid,
            fields
          })
        })
      })
    },
    handleBlur () {
      this.$emit('input', { ...this.localForm })
    },
    handleTelCountryCodeChange () {
      this.localForm.nationality = this.localForm.telCountryCode
      this.$emit('input', { ...this.localForm })
    }
  }
}
